<div class="messages-table-section">
  <form [formGroup]="liveDelFormGroup">
  <div *ngIf="showTitleSection" class="d-flex align-items-center">
    <div class="mr-auto">
      <span class="title-table-section">{{ tableName }}</span>
    </div>

    <ng-container *ngIf="showActionSection">
      <div *ngIf="isSwitchOn" class="switch">
          <label class="title-event">
            <span class="switch-title" [ngStyle]="!showLiveMessages ? {'color': '#1d1757'} : {'color': '#9c9c9c'}">
              Deleted
            </span>
            <input type="checkbox" formControlName="showLiveMessages">
            <span class="lever"></span>
            <span class="switch-title" [ngStyle]="showLiveMessages ? {'color': '#1d1757'} : {'color': '#9c9c9c'}">
              Live
            </span>
          </label>
      </div>

      <div *ngIf="isSearchOn">
        <button mdbBtn mdbWavesEffect
                outline="true" rounded="true"
                size="sm" type="button"
                class="px-2 m-0 ml-3 table-button"
                (click)="openFilter()">
          <mdb-icon class="table-icon" fas size="lg" icon="search"></mdb-icon>
        </button>
      </div>

      <div mdbDropdown class="btn-group ml-3 m-0">
        <button *ngIf="actionList?.length" mdbDropdownToggle mdbBtn mdbWavesEffect
                color="white"  outline="true"
                rounded="true" size="sm" type="button"
                class="px-2 header-button dropdown-toggle table-button">
          <mdb-icon fas size="lg" class="table-icon" icon="cog"></mdb-icon>
        </button>
        <div class="dropdown-menu dropdown-primary modal-list">
          <ng-container *ngFor="let action of actionList">
            <a class="dropdown-item"
               [ngClass]="{ 'disabled': disableOption(action) }"
               (click)="tableAction(action)">
              {{ actionNames[action] + (actionTypes[action] === actionTypes.uploadList ? uploadListName : '') }}
            </a>
          </ng-container>
        </div>
      </div>

      <button mdbBtn mdbWavesEffect
              size="sm" type="button"
              class="px-2 btn-width header-button ml-3 table-button"
              (click)="columnOptions.toggle()">
        <mdb-icon fas size="lg" class="table-icon" icon="columns"></mdb-icon>
      </button>

      <button *ngIf="isExportEnable"
        mdbBtn mdbWavesEffect
              size="sm" type="button"
              class="px-2 btn-width header-button mr-3 table-button no-box-shadow"
              (click)="export()">
        <mdb-icon fas size="lg" class="blue-icon" icon="file-download"></mdb-icon>
      </button>
    </ng-container>
  </div>

  <hr *ngIf="showTitleSection" class="my-1">

  <div class="row text-left mb-2" mdbCollapse #columnOptions="bs-collapse">
    <div class="filter-options-div">
      <button type="button" class="btn-okay relative waves-light rounded btn-filter-options" mdbRipple (click)="selectDeselectAllFields(true)">Select All</button>
      <button type="button" class="btn-okay relative waves-light rounded btn-filter-options" mdbRipple (click)="selectDeselectAllFields(false)">Deselect All</button>
      <button *ngIf="showFieldsFiltersFully" type="button" class="btn-okay relative waves-light rounded btn-filter-options" mdbRipple (click)="openFilterOptionsModal()">Save / Delete</button> 
      
      <mat-form-field class="filters-form-field" *ngIf="showFieldsFiltersFully">
        <mat-label>Select Filter</mat-label>
        <mat-select class="filters-dropdown" (selectionChange)="changeFilter($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFieldsFilter">
          <mat-option></mat-option>
          <mat-option *ngFor="let filter of selectedFieldsFilters" [value]="filter">
            {{filter.filterName}}
          </mat-option>
        </mat-select>        
      </mat-form-field> 
    </div>    
    <div class="col-3 font-size-12" *ngFor="let column of hideColumns; index as i">
      <mat-checkbox class="cursor-default-style" [checked]="column.checked" [name]="column.name" (change)="toggle($event)">
        {{ column.displayName }}
      </mat-checkbox>
    </div>
  </div>

  <div *ngIf="tableHasRows"
       class="mat-table-container"
       [ngClass]="{'trial-run-table-container': tableType === tableTypes.trial}">
    <table class="main-table-component" [ngClass]="{'link-clicks-table': ownerPage === 'linkclicks'}" mat-table matSort [dataSource]="data" (matSortChange)="onSortColumn()" (contentChanged)="dataLoaded()">
      <ng-container *ngFor="let column of displayedColumns"
                    [matColumnDef]="column.name"
                    [stickyEnd]="column.name === 'actions'">
        <ng-container *ngIf="column.name === 'checkBox'">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="checkAllMessages()"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" >
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="checkMessage(row)"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'status'">
          <td mat-cell *matCellDef="let row" [ngClass]="'status-column'">
            <div class="switch">
              <label class="title-event">
                <span class="switch-title" [ngStyle]="!row.status ? {'color': '#1d1757'} : {'color': '#9c9c9c'}">
                  Off
                </span>
                <input [disabled]="!this.permission.Edit"
                       (click)="switchContactPlan(row)"
                       [checked]="row.status"
                       type="checkbox">
                <span class="lever"></span>
                <span class="switch-title" [ngStyle]="row.status ? {'color': '#1d1757'} : {'color': '#9c9c9c'}">
                  On
                </span>
              </label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'actions'" matColumnDef="action" stickyEnd="true">
          <td mat-cell *matCellDef="let row">
            <div class="d-flex">
              <i *ngIf="tableType === tableTypes.contacts"
                 class="fas icon-size fa-history icon-color px-1"
                 (click)="getMessageHistory(row)">
              </i>

              <i *ngIf="tableType === tableTypes.contactCriteria"
                 class="fas icon-size fa-search icon-color px-1"
                 (click)="trialOpen(row)">
              </i>
              
              <i *ngIf="(tableType === tableTypes.lists) && permission.View"
                 class="fab icon-size fa-wpforms icon-color px-1"
                 (click)="editForms(row)">
              </i>

              <i *ngIf="tableType !== tableTypes.emailReports || tableType === tableTypes.smsReports ? editPermissionValidator(row) : false"
                 class="far icon-size fa-edit icon-color px-1"
                 (click)="edit(row)">
              </i>

              <i *ngIf="(tableType === tableTypes.lists) && permission.View"
                 class="far icon-size fa-user-circle icon-color px-1"
                 (click)="listContacts(row)">
              </i>

              <i *ngIf="tableType === tableTypes.contactCriteria && this.isLoggedInUserMDUser()"
                 class="fas icon-size fa-copy icon-color px-1"
                 (click)="copyContactCriteriaGroup(row)">
              </i>

              <i *ngIf="tableType !== tableTypes.emailReports || tableType === tableTypes.smsReports ? deletePermissionValidator(row) : false"
                 class="fas icon-size icon-color px-1"
                 [ngClass]="{ 'fa-trash': showLiveMessages, 'fa-backward': !showLiveMessages }"
                 (click)="deleteRestore(row)">
              </i>

              <i *ngIf="tableType === tableTypes.emailReports || tableType === tableTypes.smsReports"
                 class="fas icon-size fa-arrow-alt-circle-right icon-color px-1"
                 (click)="openDetails(row)">
              </i>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'link'">
          <td mat-cell *matCellDef="let row" class="d-flex flex-row">
            <div class="col-md-4 d-flex align-items-center px-0">
              <div *ngIf="row.LinkTitle.length; else elseBlock">
                <a href="{{ row.URL }}">{{ row.LinkTitle }}</a>
              </div>

              <ng-template #elseBlock>
                <a class="smallLinkText" href="{{ row.URL }}">{{ row.URL }}</a>
              </ng-template>
            </div>

            <div class="col-md-8 py-1">
              <div class="row">
                <div class="col-12">
                  <div mdbTooltip="Unique Clicks" placement="left" class="progress">
                    <div class="progress-bar unique-clicks" role="progressbar"
                         [ngStyle]="{'width': (row.UniqueClicks > 0 ? (row.UniqueClicks / row.NonUniqueClicks * 100) : 0).toFixed(0) + '%'}"
                         aria-valuenow="{{ (row.UniqueClicks > 0 ? (row.UniqueClicks / row.NonUniqueClicks * 100) : 0).toFixed(0) }}"
                         aria-valuemin="0"
                         aria-valuemax="100">
                      {{ (row.UniqueClicks > 0 ? (row.UniqueClicks / row.NonUniqueClicks * 100) : 0).toFixed(0) }}%
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-2">
                <div class="col-12">
                  <div mdbTooltip="Total Clicks" placement="left" class="progress">
                    <div class="progress-bar non-unique-clicks" role="progressbar"
                         [ngStyle]="{'width': (row.TotalNonUniqueClicksOnAllLinks ? (row.NonUniqueClicks / row.TotalNonUniqueClicksOnAllLinks * 100) : 0).toFixed(0) + '%'}"
                         aria-valuenow="{{ (row.TotalNonUniqueClicksOnAllLinks ? (row.NonUniqueClicks / row.TotalNonUniqueClicksOnAllLinks * 100) : 0).toFixed(0) }}"
                         aria-valuemin="0"
                         aria-valuemax="100">
                      {{ (row.TotalNonUniqueClicksOnAllLinks ? (row.NonUniqueClicks / row.TotalNonUniqueClicksOnAllLinks * 100) : 0).toFixed(0) }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container>
          <td mat-cell *matCellDef="let element"  title="{{element[column.name] != null && element[column.name].length > 38 ? element[column.name] : ''}}"
              [ngStyle]="element[column.name] != null && element[column.name].length > 38  ? {'cursor': 'default'} : {'cursor': 'text'}">
            {{ column.name === 'createDate' || column.name === 'changeDate' ? (element[column.name] | date:'dd/MM/yyyy HH:mm:ss') : element[column.name] }}
          </td>
        </ng-container>

        <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef> {{ column.displayName }} </th>
      </ng-container>

      <ng-container *ngIf="columnsToDisplay?.length >= 1">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      </ng-container>
      <tr *matRowDef="let row; columns: columnsToDisplay;"   
          mat-row
          [class.checked]="selection.isSelected(row)"
          class="element-row">
      </tr>
    </table>
  </div>

  <div class="paginator-container" *ngIf="tableHasRows">
    <div class="page-select">
      <div class="label">Page: </div>
      <mat-form-field class="gotopage_form_filed">
        <mat-select class="gotopage_dropdown" (selectionChange)="goToPage($event)" [(value)]="currentPage">
          <mat-option *ngFor="let page of pages" [value]="page.value">
            {{page.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>      
    </div>

    <mat-paginator class="mat-paginator-sticky"
                   [hidden]="!tableHasRows"
                   [length]="length"
                   [pageSize]="pageLimit"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageIndex]="pageIndex"
                   (page)="onChangePage($event)">

    </mat-paginator>  
  </div> 

  <div *ngIf="!tableHasRows">
    <span>No data to display.</span>
  </div>
</form>
</div>


<div mdbModal #filterOptionsModal="mdbModal" class="modal fade top rounded" id="filterOptionsModal" tabindex="-1" role="dialog"
       aria-labelledby="uploadFileModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
       <div class="modal-content">
	         <div class="modal-header fields-filters-modal-header">
           <p class="heading lead">Filter Options</p>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="filterOptionsModal.hide()">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body">
           <div class="save-filter-region">
             <p class="filter-label">Save New Filter</p>
             <input type="text"
                     id="newfiltername"
                     class="form-control"
                     placeholder="Enter filter name"
                     [(ngModel)]="newFilterName"
                     mdbInput>
                     
             <button type="button" (click)="saveNewFilter()" class="btn-filter-save btn-okay relative waves-light rounded" [disabled]="columnsToDisplay?.length <= 1 || !newFilterName" mdbWavesEffect>Save Filter</button>        
           </div>
           
           <div class="delete-filter-region">
             <p class="filter-label-delete">Delete Existing Filter</p>
             <mat-form-field class="delete-filter-form-field" [floatLabel]="'never'">
               <mat-label>Select Filter</mat-label>
               <mat-select class="filters-dropdown" [(value)]="selectedFieldsFilterForDelete" >
                 <mat-option *ngFor="let filter of selectedFieldsFilters" [value]="filter">
                   {{filter.filterName}}
                 </mat-option>
               </mat-select>
             </mat-form-field> 
 
             <button type="button" (click)="deleteFilter()" class="btn-filter-delete btn-okay relative waves-light rounded" [disabled]="!selectedFieldsFilters?.length || !selectedFieldsFilterForDelete" mdbWavesEffect>Delete Filter</button> 
           </div>
           
         </div>
       </div>
     </div>
 </div>