import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { NetworkAdapter } from 'src/app/_models/social-media/networks/network-adapter';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { NetworkService } from 'src/app/_services/social-media/networks/network.service';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import ConstTableRowLimitOptions, { Selects } from 'src/app/views/shared/constants/app-constants';
import { ReportService } from 'src/app/_services/social-media/reports/report.service';
import { SocialReportDtoRequest } from 'src/app/_models/social-media/reports/social-report-dto-request';
import { TwitterMetricsReportDto } from 'src/app/_models/social-media/reports/twitter-metrics-report-dto';
import { FacebookMetricsReportDto } from 'src/app/_models/social-media/reports/facebook-metrics-report-dto';
import ReportingErrorMessages from 'src/app/views/shared/constants/app-constants';
import { InstagramMetricsReportDto } from 'src/app/_models/social-media/reports/instagram-metrics-report-dto';
import { LinkedInMetricsReportDto } from 'src/app/_models/social-media/reports/linkedin-metrics-report-dto';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import * as moment from 'moment';
import { IconPath, social } from '../../constants/social-media-constants';
import { ErrorAlertComponent } from '../../../shared/error-alert/error-alert.component';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';
import { PagerModelAdapter } from '../../../../_models/system/pager-model';
import { PostViewModel } from '../../../../_models/social-media/posts/post-view.model';
import { PostsService } from '../../../../_services/social-media/posts/posts.service';
import { DateConverterService } from '../../../../_services/social-media/date-converter.service';

@Component({
  selector: 'app-single-network-report',
  templateUrl: './single-network-report.component.html',
  styleUrls: ['./single-network-report.component.scss']
})
export class SingleNetworkReportComponent implements OnInit {
  totalCount: number;
  errorMessages = ReportingErrorMessages;
  metrics;
  posts: PostViewModel[];
  headLabel = social.insights;
  emptyState = Selects.emptyDate;
  singleNetworkReportForm: UntypedFormGroup;
  userPermissions: PermissionsModel;
  networkType = NetworkTypeEnum;
  pageTitle = 'Single Network Report';
  noDataAvailableError = false;
  currentTab  = 'Overview';
  channelDropdown: Array<any> = [];
  networksDropdown: Array<any> = [];
  networksFilteredDropdown: Array<any> = [];
  networks: NetworkDto[] = [];
  account: NetworkDto[] = [];
  singleNetworkReportSearched = false;
  selectedNetworkType = 0;
  selectedNetworkDetailID = 0;
  selectedNetworkName = '';
  isSearchEnabled = false;
  isPickerOpen = false;
  searched = false;
  pageLimitOptions = ConstTableRowLimitOptions;
  postCountReportHasData = false;
  postCountReportColumns: DataTableColumn[] = [];
  postCountReportRows: { [prop: string]: any }[] = [];
  postCountCurrentPage = 1;
  postCountPageCount = 0;
  postCountPageLimit = 10;
  postCountPageOffSet = 0;
  postCountPageSizeOptions: number[] = [10, 25, 50, 100];
  math = Math;
  moment: any = moment;
  array = Array;

  @ViewChild('errorAlert', { static: true }) errorAlert: ErrorAlertComponent;

  constructor(
    private networkService: NetworkService,
    private networkAdapter: NetworkAdapter,
    private permissionService: PermissionsService,
    private reportService: ReportService,
    private loadingSpinnerService: LoadingSpinnerService,
    private helperService: HelperService,
    private pagerAdapter: PagerModelAdapter,
    private postsService: PostsService,
    private dateConvert: DateConverterService
  ) { }

  ngOnInit(): void {
    this.userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this.userPermissions = permissions;
    });

    this.singleNetworkReportFormGroup();
    this.populateNetworkAndChannelDropdown();
  }

  singleNetworkReportFormGroup(): void {
    this.singleNetworkReportForm = new UntypedFormGroup({
      selectedChannel: new UntypedFormControl([''], Validators.required),
      selectedNetwork: new UntypedFormControl({ value: [''], disabled: true }, Validators.required),
      dateTimeFrom: new UntypedFormControl('', Validators.required),
      dateTimeTo: new UntypedFormControl('', Validators.required)
    }, {
      validators: [this.dateValidator]
    });
  }

  dateValidator(formGroup: UntypedFormGroup): null | { dateValidationError: true } {
    const fromDate: string = formGroup.get('dateTimeFrom').value;
    const toDate: string = formGroup.get('dateTimeTo').value;

    if (toDate && fromDate) {
      if ((toDate.includes(':') && toDate.includes('-')) && (fromDate.includes(':') && fromDate.includes('-'))) {
        const convertedFromDate: Date = new Date(fromDate);
        const convertedToDate: Date = new Date(toDate);
        if (convertedFromDate < convertedToDate) {
          return null;
        }
      }
    }

    return {dateValidationError: true};
  }

  pickerIsOpen(isOpen: boolean): void {
    this.isPickerOpen = isOpen;
  }

  populateNetworkAndChannelDropdown(): void {
    this.networkService.get().subscribe((result: ExecutionResultDto) => {
      result.data.Networks.forEach((networks: NetworkDto) => {
        this.account = [... this.account, this.networkAdapter.adapt(networks)];
      });

      this.account.forEach((account: NetworkDto) => {
        if (account.type === NetworkTypeEnum.Facebook || account.type === NetworkTypeEnum.LinkedIn) {
          account.subNetworks.forEach((network: NetworkDto) => {
            this.networks = [... this.networks, network];
          });
        } else {
          this.networks = [... this.networks, account];
        }
      });

      this.networks.forEach((network) => {
        this.networksDropdown = [...this.networksDropdown, {
          value: network.networkDetailId,
          label: network.name,
          type: network.type,
          icon: IconPath[network.type]
        }];
      });

      this.networksDropdown.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }

        return 0;
      });

      const uniqueNetworks = [...new Map(this.networksDropdown.map((item) => [item.type, item])).values()];
      uniqueNetworks.forEach((network) => {
        this.channelDropdown = [...new Set(this.channelDropdown), {
          value: network.type,
          label: NetworkTypeEnum[network.type],
          icon: IconPath[network.type]
        }];
      });
    });
  }

  channelSelected($event): void {
    this.isSearchEnabled = false;
    this.singleNetworkReportForm.get('selectedNetwork').enable();
    this.networksFilteredDropdown = this.networksDropdown.filter(channel => channel.type === $event.value);
  }

  resetFields(): void {
    this.noDataAvailableError = false;
    this.currentTab = 'Overview';
    this.reportService.resetTextFilter();
    this.searched = false;
  }

  networkSelected($event): void {
    this.singleNetworkReportSearched = false;
    this.resetFields();
    this.selectedNetworkType = $event.type;
    this.selectedNetworkDetailID = $event.value;
    this.selectedNetworkName = $event.label;
    this.isSearchEnabled = true;
  }

  searchInsights(paginationData?): void {
    if (!paginationData) {
      this.postsService.resetPagination();
    }

    this.resetFields();
    this.loadingSpinnerService.loading();
    const dateTimeFrom = this.dateConvert.noOffsetConvert(this.singleNetworkReportForm.get('dateTimeFrom').value);
    const dateTimeTo = this.dateConvert.noOffsetConvert(this.singleNetworkReportForm.get('dateTimeTo').value);
    const socialReportDtoRequest = new SocialReportDtoRequest(
      dateTimeFrom,
      dateTimeTo,
      this.selectedNetworkType,
      165,
      this.selectedNetworkDetailID
    );

    this.reportService.getOverviewMetrics(socialReportDtoRequest).subscribe((overviewMetrics: ExecutionResultDto) => {
      const metrics = overviewMetrics.data;
      const dtoList = {
        2: new TwitterMetricsReportDto(metrics),
        3: new FacebookMetricsReportDto(metrics),
        4: new InstagramMetricsReportDto(metrics),
        5: new LinkedInMetricsReportDto(metrics)
      };

      this.metrics = dtoList[this.selectedNetworkType];
      this.posts = this.selectedNetworkType === NetworkTypeEnum.Twitter ? this.metrics.Tweets : this.metrics.Posts;
      this.totalCount = overviewMetrics.data.TotalPosts;
      this.populatePostCount(this.posts);
      this.singleNetworkReportSearched = true;
      this.loadingSpinnerService.stopLoading();
    }, error => {
      console.log(error);
      this.noDataAvailableError = true;
      this.singleNetworkReportSearched = false;
      this.loadingSpinnerService.stopLoading();
    });
  }

  findAuthorOccurrences(networkPostData: PostViewModel[], authorColumn, countColumn): {Author: string, Count: number}[] {
    const _tempArray = [];
    networkPostData.forEach((value) => {
      if (_tempArray.some((val) => val[authorColumn] === value[authorColumn])) {
        _tempArray.forEach((k) => {
          if (k[authorColumn] === value[authorColumn]) {
            k[countColumn]++;
          }
        });
      } else {
        const countProp = {};
        countProp[authorColumn] = value[authorColumn];
        countProp[countColumn] = 1;
        _tempArray.push(countProp);
      }
    });

    return _tempArray;
  }

  populatePostCount(networkPostData: PostViewModel[]): void {
    this.postCountReportRows = [];
    this.postCountReportColumns = [];
    if (networkPostData.length > 0) {
      this.postCountReportHasData = true;
      this.postCountReportColumns.push(new DataTableColumn({ prop: 'Author', name: 'App/Admin', visible: true }));
      this.postCountReportColumns.push(new DataTableColumn({ prop: 'Count', name: 'Count', visible: true }));
      this.postCountReportRows = this.findAuthorOccurrences(
        networkPostData, this.postCountReportColumns[0].prop, this.postCountReportColumns[1].prop
      );
      this.postCountPageCount = this.postCountReportRows.length;
    }
  }

  postCountPaginateTable(event?: any): void {
    if (event !== undefined) {
      this.postCountPageLimit = event.pageSize;
      this.postCountCurrentPage = + event.pageIndex + 1;
      this.postCountPageOffSet = + event.pageIndex;
    }
  }

  exportPostCountTable(): void {
    const today = new Date();
    const fileName = `${this.pageTitle} ${NetworkTypeEnum[this.selectedNetworkType]} PostCount ${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

    this.helperService.exportToCSV(fileName, this.postCountReportRows);
  }
}
