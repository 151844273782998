import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { MDBDatePickerComponent, ClockPickerComponent, ModalDirective } from 'ng-uikit-pro-standard';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { SearchDataService } from 'src/app/_services/tables/search-data/search-data.service';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-contact-interactions',
  templateUrl: './contact-interactions.component.html',
  styleUrls: ['./contact-interactions.component.scss']
})
export class ContactInteractionsComponent implements OnInit, AfterViewInit {
  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _exportRows: { [prop: string]: any }[] = [];
  _exporting = false;
  _folderSelectListOptions: SelectListOption[] = [];
  _interactionsFieldsFormGroup: UntypedFormGroup;
  _userPermissions: PermissionsModel;
  _rows: { [prop: string]: any }[] = [];
  _selectedListId: number = 0;
  _tableHasRows = false;
  _filterInteractionRules = '';
  _pageSizeOptions: number[] = [10, 25, 50, 100];

  //Default column sorting value variables
  _sortColumn: string = 'UpdatedDate';
  _sortDirection: string = 'Descending';

  // Timeout variable for spinner modal
  _timeout: number;


  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  // variables for pagination Data
  _pageTitle = 'Contact-Interactions';

  isFilterDialogOpenningFirstTime: boolean = true;

  @ViewChildren(MDBDatePickerComponent) datepickers: QueryList<MDBDatePickerComponent>;
  @ViewChildren(ClockPickerComponent) timepickers: QueryList<ClockPickerComponent>;
  @ViewChild('filterInteractionsModal', { static: true }) filterInteractionsModal: ModalDirective;
  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;

  constructor(
    private contactService: ContactService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private router: Router,
    private datePipe: DatePipe,
    private paginationDataService: PaginationDataService,
    private searchDataService: SearchDataService,
    private helperService: HelperService,
    private loadingSpinnerService: LoadingSpinnerService
  ) { }

  ngOnInit() {

    // Get pagination data stored in a session
    this.paginationDataService.GetPaginationDataFromLocalStorage();
    this.paginationDataService.GetFolderIdFromLocalStorage();
    this.paginationDataService.GetListIdFromLocalStorage();

    if (this.paginationDataService.paginationData.lastPage === this._pageTitle && this.paginationDataService.paginationData.page) {
      this._currentPage = this.paginationDataService.paginationData.page;
      this._pageLimit = this.paginationDataService.paginationData.pageLimit;
      this._sortColumn = this.paginationDataService.paginationData.sortByColumn;
      this._sortDirection = this.paginationDataService.paginationData.sortByDirection;
    }

    if (this.searchDataService.searchData.lastPage === this._pageTitle) {
      this._filterInteractionRules = this.searchDataService.searchData.searchTerm;
    }

    if (this.paginationDataService.paginationData.lastPage != this._pageTitle) {
      this.paginationDataService.SetPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
      this.searchDataService.SetSearchData('', this._pageTitle);
    }

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.filterInteractionsModal.onShown.asObservable().subscribe(() => {
      if (this.isFilterDialogOpenningFirstTime) {
        this.initQueryBuilder();
        this.isFilterDialogOpenningFirstTime = false;
      } else {        
        this._filterInteractionRules = '';
      }
    })
  }

  ngAfterViewInit() {
    this.getContactInteractions();
  }

  export(pageInfo?: any) {
    try {
      this._exporting = true;

      const pageNumber = pageInfo ? pageInfo : 1;

      const getContactInteractionsPagerDtoRequest = new PagerDtoRequest({
        page: pageNumber,
        pageSize: this._pageLimit,
        sortColumn: this._sortColumn,
        sortDirection: this._sortDirection
      });


      this.contactService.getContactInteractions(getContactInteractionsPagerDtoRequest, this._filterInteractionRules).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._exportRows = this.getRows(executionResultDto, true);
          const today = new Date();
          const fileName = `${this._pageTitle}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

          this.helperService.exportToCSV(fileName, this._exportRows);

        } else {
          this.notificationService.showError(executionResultDto.message);
          return;
        }
        this._exporting = false;
      });
    } catch (error) {
      this.notificationService.showError(error.message);
      this._exporting = false;
    }
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(event?: any) {

    if (event !== undefined) {
      this._pageLimit = event.pageSize;
      this._currentPage = + event.pageIndex + 1;
      this._pageOffset = + event.pageIndex;
    }

    this.paginationDataService.SetPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
    this.getContactInteractions(this._currentPage);
  }

  // optional variables that allow dynamic sorting as data is retrieved from the database
  // rather than all stored client side
  getContactInteractions(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) {
      this.paginationDataService.paginationData.page = pageInfo;
    }
    else if (this.paginationDataService.paginationData.page) {
      pageNumber = this.paginationDataService.paginationData.page;
    }

    this.paginationDataService.SetPaginationDataToLocalStorage(pageNumber, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);

    const getContactInteractionsPagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    this.contactService.getContactInteractions(getContactInteractionsPagerDtoRequest, this._filterInteractionRules).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.length;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._allColumns = this._columns = this._rows = [];

        if (!this._tableHasRows) {
          this.loadingSpinnerService.stopLoading();
          return;
        }

        // hardcoded column headers for now
        this._allColumns = [new DataTableColumn({ name: "Email", prop: "Email", visible: true }),
        new DataTableColumn({ name: "Consent Email", prop: "Consent_Email", visible: true }),
        new DataTableColumn({ name: "Bounced", prop: "Bounced", visible: true })];
        this._columns = this._allColumns;
        this._rows = this.getRows(executionResultDto);

      } else {
        this.loadingSpinnerService.stopLoading();
        this.notificationService.showError(executionResultDto.message);
      }

      this.loadingSpinnerService.stopLoading();
    });
  }

  // add ability to dynamically sort data retrieved from the database by providing the direction and column being sorted
  // we also need to ensure that the captured directions are formatted to work with the API and SQL script
  onSort($event) {

    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.loadingSpinnerService.loading();
    this.getContactInteractions(undefined, direction, $event.sorts[0].prop);
  }

  getRows(executionResultDto: ExecutionResultDto, exporting?) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        } else {
          if (exporting) {
            row[key] = '';
          }
        }
      });
      return row;
    });
    return rows;
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  isChecked(col) {
    return (
      this._columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  isValid(controlName: string): boolean {
    return this._interactionsFieldsFormGroup.get(controlName) && !this._interactionsFieldsFormGroup.get(controlName).invalid &&
      (this._interactionsFieldsFormGroup.get(controlName).touched || this._interactionsFieldsFormGroup.get(controlName).dirty);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this._columns = this._columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this._columns = [...this._columns, col];
    }
  }

  interactionsFilterSave(event) {
    if (!event[1]) {
      this._filterInteractionRules = '';
    } else {
      this._filterInteractionRules = event[1].sql;
    }
    this.searchDataService.SetSearchData(this._filterInteractionRules, this._pageTitle);
    this.getContactInteractions();
    this.filterInteractionsModal.hide();
  }

  onActivate(event) {
    if (event.type === 'click') {
      this.router.navigate(['/consent-history', { data: event.row["Email"] }]);
    }
  }

  initQueryBuilder() {
    this.queryBuilder.getListFields(this._selectedListId, 'interactions');
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this.loadingSpinnerService.loading();
    this._pageLimit = parseInt(limit);
    this.getContactInteractions();
  }
}
