<form [formGroup]="actionsToPerformFormGroup">
  <div class="shorten">
    <div class="switch">
      <label class="slider-label">
        <input formControlName="sendContactMessageToggle" type="checkbox" checked>
        <span class="lever"></span>Send the Contact the Message
      </label>
    </div>

    <div *ngIf="this._showSendForm" class="offset" [@slide]>
      <form [formGroup]="sendContactMessage">
        <p class="text-dark">Select type of message</p>

        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" id="send-message-email-type" name="sendMessageType"
            formControlName="sendMessageType" [value]="1">
          <label class="form-check-label" for="send-message-email-type">Email</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" id="send-message-sms-type" name="sendMessageType"
            formControlName="sendMessageType" [value]="2">
          <label class="form-check-label" for="send-message-sms-type">SMS</label>
        </div>

        <div class="md-form remove-bottom-margin">
          <mdb-select [options]="_messageFolderDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="Select Folder" formControlName="messageFolder" (selected)="sendMessageFolderSelected($event)">
          </mdb-select>
        </div>
        <div class="md-form remove-bottom-margin">
          <div class="row">
            <div class="col-10">
              <mdb-select [options]="_sendMessageDropdown" [filterEnabled]="true" [visibleOptions]="10"
                label="Select Message" formControlName="message">
              </mdb-select>
            </div>
            <div class="col-2">
              <a *ngIf="this._sendContactsSelectedMessageId > 0" (click)="showSendContactsMessagePreviewModal()"
                mdbTooltip="preview message" placement="top">
                <mdb-icon far icon="eye" size="lg"></mdb-icon>
              </a>
            </div>
          </div>
          <div class="alert alert-warning remove-padding"
            *ngIf="this.createEditTriggerComponent.showValidationError(['actionsToPerformFormGroup', 'sendContactMessage', 'message'])">
            This field is required.</div>
        </div>
      </form>
    </div>

    <div class="switch">
      <label class="slider-label">
        <input formControlName="notifyContactMessageToggle" type="checkbox" checked>
        <span class="lever"></span> Notify these Contacts, with the Message
      </label>
    </div>

    <div *ngIf="this._showNotifyForm" class="offset" [@slide]>
      <form [formGroup]="notifyContactMessage">

        <p class="mt-2 text-dark font-weight-bold">Contacts</p>
        <div *ngIf="this.selectContactsComponent._selectedContactsEmail.length === 0"
          class="scrollbar scrollbar-primary force-overflow contact-list-empty" (click)="selectContactsModal.show()">
          <p class="contact-placeholder remove-bottom-margin">Select Contacts</p>
        </div>
        <div *ngIf="this.selectContactsComponent._selectedContactsEmail.length > 0"
          class="scrollbar scrollbar-primary force-overflow contact-list text-left"
          (click)="selectContactsModal.show()">
          <span *ngFor="let contact of this.selectContactsComponent._selectedContactsEmail"
            class="contact-filter-string text-dark">{{contact.Email}}, </span>
        </div>
        <div class="alert alert-warning remove-padding mt-1"
          *ngIf="this.selectContactsComponent._selectedContactsEmail.length === 0">This field is required.</div>

        <p class="text-dark mt-3">Select type of message</p>
        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" id="notify-message-email-type" name="messageType"
            formControlName="messageType" [value]="1">
          <label class="form-check-label" for="notify-message-email-type">Email</label>
        </div>

        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" id="notify-message-sms-type" name="messageType"
            formControlName="messageType" [value]="2">
          <label class="form-check-label" for="notify-message-sms-type">SMS</label>
        </div>

        <div class="md-form remove-bottom-margin">
          <mdb-select [options]="this._messageFolderDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="Select Folder" formControlName="messageFolder" (selected)="notifyMessageFolderSelected($event)">
          </mdb-select>
        </div>
        <div class="md-form remove-bottom-margin">

          <div class="row">
            <div class="col-10">
              <mdb-select [options]="_notifyMessageDropdown" [filterEnabled]="true" [visibleOptions]="10"
                label="Select Message" formControlName="message">
              </mdb-select>
            </div>
            <div class="col-2">
              <a *ngIf="this._notifyContactsSelectedMessageId > 0" (click)="showNotifyContactsMessagePreviewModal()"
                mdbTooltip="preview message" placement="top">
                <mdb-icon far icon="eye" size="lg"></mdb-icon>
              </a>
            </div>
          </div>
          <div class="alert alert-warning remove-padding"
            *ngIf="this.createEditTriggerComponent.showValidationError(['actionsToPerformFormGroup', 'notifyContactMessage', 'message'])">
            This field is required.</div>
        </div>
      </form>
    </div>

    <div class="switch">
      <label class="slider-label">
        <input formControlName="updateValuesToggle" type="checkbox" checked>
        <span class="lever"></span> Update these Values
      </label>
    </div>

    <div *ngIf="this._showUpdateValuesForm" class="offset" [@slide]>
      <form [formGroup]="updateValues">
        <p class="mt-2 text-dark font-weight-bold">Change the following value(s) in the Contact record...</p>
        <div *ngIf="this._readableContactValues.length === 0"
          class="scrollbar scrollbar-primary force-overflow contact-list-empty margin-bottom-5-px"
          (click)="updateContactValueModal.show()">
          <p class="contact-placeholder remove-bottom-margin">Select Contact Values</p>
        </div>
        <div *ngIf="this._readableContactValues.length > 0"
          class="scrollbar scrollbar-primary force-overflow contact-list text-left margin-bottom-5-px"
          (click)="updateContactValueModal.show()">
          <div *ngFor="let values of this._readableContactValues" class="contact-filter-string text-dark">
            <span *ngFor="let value of values">
              {{value}}
            </span>
          </div>
        </div>
      </form>
    </div>

    <div class="switch" *ngIf="this._showSendViaApiSwitch">
      <label class="slider-label">
        <input formControlName="sendViaApiToggle" type="checkbox" checked>
        <span class="lever"></span> Send via API
      </label>
    </div>

    <div *ngIf="this._showSendViaApiForm && this._showSendViaApiSwitch" class="offset" [@slide]>
      <form [formGroup]="sendViaApiForm">
        <div class="md-form remove-bottom-margin">
          <mdb-select [options]="this._triggerApiDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="Select API" formControlName="triggerApi" (selected)="notifyTriggerApiSelected()">
          </mdb-select>
        </div>
        <div class="md-form remove-bottom-margin" *ngIf="this._showSendApiSystem">
          <mdb-select [options]="this._availableSystemDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="Select System" formControlName="system">
          </mdb-select>
        </div>
        <div class="md-form remove-bottom-margin" *ngIf="this._showSendApiLocation">
          <mdb-select [options]="this._availableLocationsDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="Select Location" formControlName="location">
          </mdb-select>
        </div>
        <div class="md-form remove-bottom-margin" *ngIf="this._showSendApiSystem || this._showSendApiLocation">
          <mdb-select [options]="this._apiSectionDropdown" [filterEnabled]="true" [visibleOptions]="10"
            label="API Section" formControlName="apiSection" (selected)="notifyApiSectionSelected()">
          </mdb-select>
        </div>

        <div class="api-section-fields-mapping-div" *ngIf="this._showSendApiFieldsMapping">
          <div class="row">
            <div class="md-form remove-bottom-margin col-md-4 remove-padding-right">
              <p class="mt-2 text-dark font-weight-bold">API Fields Available
                <sup class="mandatory-tooltip">*mandatory</sup>
              </p>

            </div>
            <div class="md-form remove-bottom-margin col-md-4">
              <p class="mt-2 text-dark font-weight-bold">VoiceBox List Field</p>
            </div>
          </div>          
            <div *ngFor="let item of apiIntegrationEndpointFieldsMappings; let i = index">
              <div class="row">
                <div class="md-form remove-bottom-margin col-md-3 remove-top-margin">
                  <label class="form-check-label api-mapping" title="{{item.notes}}">
                    {{item.label}}
                    <sup *ngIf="item.mandatory" class="mandatory-tooltip-big">*</sup>
                  </label>
                </div>
                <div class="md-form remove-bottom-margin col-md-1 remove-top-margin">
                  <i class="fas icon-size icon-color px-1  fa-arrow-right margin-top-20-px"></i>
                </div>
                <div class="md-form remove-bottom-margin col-md-4 remove-top-margin">
                  <label class="form-check-label api-mapping" >{{item.voiceboxDisplayName}}                    
                  </label>
                </div>
                <div class="col-1 remove-bottom-margin col-md-1 remove-top-margin">
                  <i class="fas icon-size icon-color px-1 fa-trash margin-top-20-px" (click)="removeApiFieldMapping(item.apiValue)"></i>
                </div>               
              </div>
            </div>
            <div>
              <div class="row">
                <div class="md-form remove-bottom-margin col-md-4">
                  <mdb-select [options]="this._apiIntegrationEndpointFields" [filterEnabled]="true" [visibleOptions]="10"
                    label="Select Field..." formControlName="apiIntegrationEndpoint" (selected)="notifyMappingFieldSelected()">
                  </mdb-select>                 
                </div>
                <div class="md-form remove-bottom-margin col-md-4">
                  <mdb-select [options]="this._listFieldsDropdown" [filterEnabled]="true" [visibleOptions]="10"
                    label="Select Field..." formControlName="listField" (selected)="notifyMappingFieldSelected()">
                  </mdb-select>                   
                </div>
                <div class="col-1 margin-top-34-px">
                  <i class="fas icon-size icon-color px-1 fa-plus" [ngStyle]="_isPlusButtonEnabled ? {'disabled': 'false'} : {'disabled': 'true','color':'gray'}" (click)="addApiFieldMapping()"></i>
                </div>
              </div>
            </div>          
        </div>
      </form>
    </div>

    <div class="alert alert-warning remove-padding shorter-warning" *ngIf="actionsToPerformFormGroup.hasError('actionsToPerformError') &&
    actionsToPerformFormGroup.touched" [@slide]>You must select an action.</div>

  </div>
</form>

<div mdbModal #selectContactsModal="mdbModal" class="modal fade select-contacts-modal" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div style="max-width: 80%" class="modal-dialog modal-lg modal-dialog-scrollable-broken" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="selectContactsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="myModalLabel">Select Contacts</h4>
      </div>
      <div class="modal-body">
        <app-select-contacts #selectContactsComponent [notifyContactMessage]="notifyContactMessage"
          [selectedContacts]="selectedContacts" [selectedContactIds]="selectedContactIds">
        </app-select-contacts>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="clearAll()">Clear All</button>
        <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="selectContactsModal.hide()">Done</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #updateContactValueModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="updateContactValueModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="myModalLabel">Update Contact Values</h4>
      </div>
      <div class="modal-body">
        <app-update-contact-values #updateContactValuesComponent>
        </app-update-contact-values>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect
          (click)="updateContactValueModal.hide()">Done</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #messagePreviewModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="messagePreviewModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="modalLabel">Message Preview</h4>
      </div>
      <div class="modal-body">
        <div class="scrollbar scrollbar-primary vh70">
          <div *ngIf="_messageTypeNum == 1" class="animated fadeIn desktop-view force-overflow"
            [innerHTML]="_messagePreviewHtml | sanitizeHtml"></div>
          <div *ngIf="_messageTypeNum == 2" class="phone-container">
            <div class="iphone">
              <div class="border">
                <form class="chat">
                  <span></span>
                  <div class="messages" id="adobewordpress">
                    <div class="message">
                      <div class="fromThem">
                        <p>{{this._smsMessageContent}}</p>
                        <date><b></b>{{this._smsMessageContentDate | date: 'dd/MM/yyyy H:mm a'}}</date>
                      </div>
                    </div>
                  </div>
                  <input type="text" placeholder="Your message">
                  <input type="submit" value="Send">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect
          (click)="messagePreviewModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>