import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { TriggerModel } from 'src/app/_models/messaging/automation/triggers/trigger-model';
import { TriggerModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-model.adapter';
import { TriggerTargetModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-target-model.adapter';
import { TriggerSchedulingModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-scheduling-model.adapter';
import { TriggerExecutionDataModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-execution-data-model.adapter';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EventTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/event-type.enum';
import { SchedulingTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/scheduling-type.enum';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { NotifyContactsActionInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/notify-contacts-action-info.adapter';
import { LightweightMessageModel } from 'src/app/_models/messaging/messages/lightweight-message-model';
import { LightweightMessageModelAdapter } from 'src/app/_models/messaging/messages/lightweight-message-model.adapter';
import { EmailContactModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import * as moment from 'moment';
import { AwsSecretSftpDto } from 'src/app/_models/messaging/automation/extracts/aws-secrets-sftp-dto';
import { TriggerExecutionDataModel } from 'src/app/_models/messaging/automation/triggers/trigger-execution-data-model';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { animate, style, transition, trigger } from '@angular/animations';
import { ExtractModelAdapter } from 'src/app/_models/messaging/automation/extracts/extract-model-adapter';
import { DatePipe } from '@angular/common';
import { DateService } from 'src/app/_services/system/date.service';
import { AwsSecretTagDto } from 'src/app/_models/messaging/automation/extracts/aws-secrets-tag-dto';
import { ta } from 'date-fns/locale';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';

@Component({
  selector: 'app-create-edit-extract',
  templateUrl: './create-edit-extract.component.html',
  styleUrls: ['./create-edit-extract.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ] 
})
export class CreateEditExtractComponent implements OnInit  {
  
  @ViewChild('emailPreviewModal', { static: true }) _emailPreviewModal: ModalDirective;
  @ViewChild('contactTable', { static: true }) contactTable: DatatableComponent; 
  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;
  @ViewChild('queryBuilderModal', { static: true }) queryBuilderModal: ModalDirective;
    
  triggerDetailsForm: UntypedFormGroup = new UntypedFormGroup ({
    title: new UntypedFormControl(null, Validators.required),
    triggerID: new UntypedFormControl(0),
  });

  stepForm: UntypedFormGroup = new UntypedFormGroup ({
    listForm: new UntypedFormGroup({
      triggerActionType: new UntypedFormControl('list'),
      listFolder: new UntypedFormControl(null, Validators.required),
      list: new UntypedFormControl(null, Validators.required),
      contactFilterCriteria: new UntypedFormControl(),
    }),
    scheduleForm: new UntypedFormGroup ({    
      frequency: new UntypedFormControl(null, Validators.required),
      daysOfWeek: new UntypedFormArray([], this.minSelectedCheckboxes(1)),
      dayOfMonth: new UntypedFormControl(null),
      time: new UntypedFormControl(null, Validators.required)
    }),
    fieldsForm: new UntypedFormGroup({
      fields: new UntypedFormArray([], this.minSelectedCheckboxes(1))
    }),
    sftpCredsForm: new UntypedFormGroup({ 
      awsSecretName: new UntypedFormControl(null, Validators.required),
      host: new UntypedFormControl(null),      
      username: new UntypedFormControl(null),
      password: new UntypedFormControl(null),
      confirmPassword: new UntypedFormControl(null)
    }, { validators: this.passwordMatchValidator }),
    emailConfirmationForm: new UntypedFormGroup({ 
      messageFormToggle: new UntypedFormControl(false)         
    }),  
    messageForm: new UntypedFormGroup({  
      contacts: new UntypedFormControl(null),      
      messageType: new UntypedFormControl(MessageTypeEnum.email),
      messageFolder: new UntypedFormControl(null),
      message: new UntypedFormControl(null)      
    }),
    notifiedContactsForm: new UntypedFormGroup({
      list: new UntypedFormControl(null),
      listFolder: new UntypedFormControl(null)
    })    
  });  

  get daysOfWeekArray() {
    return this.stepForm.get('scheduleForm').get('daysOfWeek') as UntypedFormArray;
  }  

  get fieldsArray() {
    return this.stepForm.get('fieldsForm').get('fields') as UntypedFormArray;
  }

  _allColumns: DataTableColumn[] = [];
  _awsSecretNamesDropdown: Array<any> = [];
  _awsSecretTagDto = new AwsSecretTagDto ({ key: 'app', value: 'extracts'})
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _dateCulture = 'en-GB';  
  _dayOfMonthDropdown: Array<any> = [];
  _daysOfMonth: Array<number> = []; 
  _daysOfWeek: Array<any> = []; 
  _eventTypeEnum: EventTypeEnum; 
  _fields = [];
  _frequencyDropdown: Array<any> = [];  
  _lightLists: Array<LightListModel> = [];
  _lightListsDropdown: Array<any> = []; 
  _listFolders: Array<ListFolderModel> = [];  
  _listFolderDropdown: Array<any> = []; 
  _listFolderDropdownForNotifiedContacts: Array<any> = [];
  _lightListsDropdownForContacts: Array<any> = [];
  _messages: Array<LightweightMessageModel> = [];
  _messageFolders: Array<FolderDto> = [];
  _messageFoldersDropdown: Array<any> = [];
  _messagePreviewHtml = ''; 
  _notifyMessageDropdown: Array<any> = [];    
  _rows: { [prop: string]: any }[] = [];
  _savingAwsSecret = false;
  _selectedAwsSecretName = '';
  _selectedContacts: Array<any> = [];
  _selectedContactsEmail: Array<EmailContactModel> = [];
  _selectedListIdForNotifiedContacts = 0;
  _selectContactsModalOpened = false; 
  _selectedNotifiedContactIds: Array<number> = [];
  _selectedMessageId = 0;
  _showAwsSecretFields = false;
  _showDayOfMonth =  false;
  _showDaysOfWeek =  false;
  _showNotifyForm: boolean = false;  
  SelectionType = SelectionType;
  _triggerToEdit: TriggerModel; 
  _triggerEdit = false;
  _triggerParameters: TriggerExecutionDataModel;
  _tableHasRows = false;
  _titleText: string = 'Create Extract Step';   
  _visibleColumns = [];
  _workflowId = 0; 
  _pageTitle = 'Create Edit Extract';
 isSelectFolder: boolean;

 // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

 // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 100;
  _pageOffset = 0;

  constructor(
    private messageFolderDtoAdapter: FolderDtoAdapter,    
    private listFolderService: ListFolderService,    
    private listsService: ListsService,   
    private listFolderModelAdapter: ListFolderModelAdapter,
    private lightListModelAdapter: LightListModelAdapter,    
    private triggerModelAdapter: TriggerModelAdapter,
    private triggerTargetModelAdapter: TriggerTargetModelAdapter,    
    private triggerSchedulingModelAdapter: TriggerSchedulingModelAdapter,
    private notifyContactsActionInfoAdapter: NotifyContactsActionInfoAdapter,
    private triggerExecutionDataModelAdapter: TriggerExecutionDataModelAdapter,
    private triggersService: TriggersService,   
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location,
    private router: Router,
    private lightweightMessageModelAdapter: LightweightMessageModelAdapter,       
    private emailMessageService: EmailMessagesService,
    private messageService: MessageService,
    private contactService: ContactService,
    private listModelAdapter: ListModelAdapter,
    private extractModelAdapter: ExtractModelAdapter,
    private dateService: DateService     
  ) {  }   


  ngOnInit() {

    this.initTriggerModel();

    this.getRouteInfo();
    
    this.subscribeToControlValueChanges();

    this.populateForm();
  }  

  addRemoveMessageValidation() {    

    const validator = this._showNotifyForm ? [Validators.required] : null; 

    const messageForm = this.stepForm.get('messageForm');
    const notifiedContactsForm = this.stepForm.get('notifiedContactsForm');
    
    messageForm.get('message').setValidators(validator);
    messageForm.get('contacts').setValidators(validator);     
    notifiedContactsForm.get('list').setValidators(validator);     
    
    messageForm.get('message').updateValueAndValidity();
    messageForm.get('contacts').updateValueAndValidity();
    notifiedContactsForm.get('list').updateValueAndValidity();     
  }
  
  addRemoveSftpCredsValidation() {  

    const sftpCredsForm = this.stepForm.get('sftpCredsForm');    

    const validator = this._showAwsSecretFields ? [Validators.required] : null;    
    
    sftpCredsForm.get('host').setValidators(validator);
    sftpCredsForm.get('username').setValidators(validator); 
    sftpCredsForm.get('password').setValidators(validator);     
    sftpCredsForm.get('confirmPassword').setValidators(validator); 
    sftpCredsForm.get('awsSecretName').setValidators(this._showAwsSecretFields ? null : [Validators.required]);
    sftpCredsForm.setValidators(this._showAwsSecretFields ? this.passwordMatchValidator : null);
   

    sftpCredsForm.get('host').updateValueAndValidity();
    sftpCredsForm.get('username').updateValueAndValidity();
    sftpCredsForm.get('password').updateValueAndValidity();     
    sftpCredsForm.get('confirmPassword').updateValueAndValidity();
    sftpCredsForm.get('awsSecretName').updateValueAndValidity(); 
    sftpCredsForm.updateValueAndValidity();    
        
  }  

  contactsFilterSave(event) {
    const text = event[0] === null && event[1] === null ? '' : event[1].sql;    
    this.stepForm.get('listForm').get('contactFilterCriteria').setValue(text);
    this.queryBuilderModal.hide();
  }

  clearAll() {
    this._selectedContacts = [];
    this._selectedContactsEmail = [];
    this._selectedNotifiedContactIds = [];    
    this.contactTable.selected = [];    
    this.stepForm.get('messageForm').get('contacts').setValue([]);       
  }

  getContactsFilterSaved() {
    return this.stepForm.get('listForm').get('contactFilterCriteria').value.replace(/(?<=\w)''(?=\w)/gm, `\'`);
  }

  getContacts(pageInfo?: any) {    

    const pageNumber = pageInfo ? pageInfo : 1;

    const pagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this._selectedListIdForNotifiedContacts
    }); 
    
    this.contactTable.rows = [];

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.Total;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._allColumns = this._columns = this._rows = [];

        if (!this._tableHasRows) {
          return;
        }

        this._allColumns = executionResultDto.data.Columns.map(column =>
          new DataTableColumn({
            prop: column.Name,
            name: column.DisplayName
          }));
        this._columns = this._allColumns;
        this._rows = this.getRows(executionResultDto);  

        this.highlightSelectedContacts();

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getId(row) {
    return row.ContactID;
  }
  
  getList(listId: number) : Promise<ExecutionResultDto> { 
    return this.listsService.getListById(listId).toPromise();      
  }

  

  getRows(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = `${date.toLocaleDateString(this._dateCulture, { day: 'numeric', month: 'numeric', year: 'numeric' })} ${date.toLocaleTimeString()}`;
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });

    return rows;
  }  

  getRouteInfo() {
    this.route.params.subscribe(params => {      
      this._workflowId = params.contactPlanId;
      if (params.triggerID) {                
        this._triggerEdit = true;
        this._titleText = 'Edit Extract Step';
      }
    });    

    this.route.data.subscribe(data => { 

      this._messageFolders = this.messageFolderDtoAdapter.adaptArray(data.messageFolders.data);

      if (data.trigger) {        
        this._triggerToEdit = this.triggerModelAdapter.adapt(data.trigger.data);
        this._triggerToEdit.Parameters = this.triggerExecutionDataModelAdapter.adapt(this._triggerToEdit.Parameters);
      }       
    });    
  } 

  getSelectedDayIds() : number[] {    

    const selectedIds = this.stepForm.get('scheduleForm').get('daysOfWeek').value
      .map((checked, i) => checked ? this._daysOfWeek[i].id : null)
      .filter(x => x!== null);

    return selectedIds;
        
  }

  getSelectedFieldIds() : number[] { 
    const selectedIds = this.stepForm.get('fieldsForm').get('fields').value
      .map((checked, i) => checked ? this._fields[i].id : null)
      .filter(x => x!== null);

    return selectedIds;        
  }

  highlightSelectedContacts() { 

    this._selectedContacts = this._rows.filter(row => this._selectedNotifiedContactIds.some(contactId => row.ContactID == contactId)); 

    this.populateEmails();      
  } 

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  async initLists(listId: number) {

    var executionResultDto = await this.getList(listId);    
    
    if (executionResultDto.executionResult === ExecutionResult.success) {      
      const list = this.listModelAdapter.adapt(executionResultDto.data[0]);      
      this.populateListFolders(list.FolderID);
      this.populateLists(list.FolderID, listId);
    } else {
      
    }   
  }

  async initListsForNotifiedContacts(listId: number) {
    var executionResultDto = await this.getList(listId);    
    
    if (executionResultDto.executionResult === ExecutionResult.success) {      
      const list = this.listModelAdapter.adapt(executionResultDto.data[0]);      
      this.populateListFolders(null, list.FolderID);
      this.populateListsForNotifiedContacts(list.FolderID, listId);
    } else {
      
    }   
  }  

  initMessages(messageId: number){
    this.triggersService.getLightMessage(messageId, MessageTypeEnum.email).subscribe(result => {
      const message = this.lightweightMessageModelAdapter.adapt(result.data);
      this.populateMessageFolders(message.FolderID);
      this.populateMessages(message.FolderID, messageId);      
    }, error => {
      console.log(error);
    });
  }

  initTriggerModel() {
    this._triggerToEdit = this.triggerModelAdapter.createEmpty();
    this._triggerToEdit.ActionType = EventTypeEnum[EventTypeEnum.Extract];
    var parameters = this.triggerExecutionDataModelAdapter.createEmpty();
    parameters.Scheduling = this.triggerSchedulingModelAdapter.createEmpty();
    parameters.Scheduling.SchedulingType = SchedulingTypeEnum.Extract
    parameters.NotifyContacts = this.notifyContactsActionInfoAdapter.createEmpty();    
    parameters.SendMessage = null;
    parameters.Extract = this.extractModelAdapter.createEmpty();

    var target = this.triggerTargetModelAdapter.createEmpty();   
    target.SubjectType = EventTypeEnum.Extract
    target.SubjectEventType = EventTypeEnum.Extract
    target.EventName = EventTypeEnum[EventTypeEnum.Extract];
    target.MessageType = MessageTypeEnum.email;
    parameters.TriggerTarget = target;    
        
    this._triggerToEdit.Parameters = parameters;
  }

  onCancelClicked() {
    this.location.back();
  } 
  
  onClosed() {
    this._selectContactsModalOpened = false
  }  

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    if (limit) {
      this._pageLimit = parseInt(limit);
      this.getContacts();
    }
  }
  
  onListFolderSelected(event) {        
    this.populateLists(+event.value);
  }  

  onlistFolderForNotifiedContactsSelected(event) {
    this.isSelectFolder = true;
    this.populateListsForNotifiedContacts(+event.value);
  }

  onlistForNotifiedContactsSelected(event){
    this._selectedListIdForNotifiedContacts = +event.value;
    this.getContacts();
  }

   onListSelected(event) {
     let isResetQueryBuilderValues = true;
     this.populateListFields(+event.value);
     this.resetQueryBuilderData(isResetQueryBuilderValues);
      
  }

  onMessageFolderSelected(event) {   
    this.populateMessages(+event.value);
  }
  
  onOpened() {
    this._selectContactsModalOpened = true;

    if (this._selectedContactsEmail.length == 0 && this.isSelectFolder) {
      this.getContacts();
    }
  }  
  
  onSelect({ selected }) { 
    
    this._selectedContacts = [];
    this._selectedContacts.push(...selected);

    this.populateEmails();
  } 

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value
      ? null : {'mismatch': true};
  }
  
  populateAwsSecrets(selectedAwsSecretName: string, tagFilter: AwsSecretTagDto) {

    this.triggersService.getAwsSecretNames(tagFilter).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) { 
        this._awsSecretNamesDropdown = executionResultDto.data.map((secretName: string) => ({
          value: secretName, 
          label: secretName.replace(`${tagFilter.value}-`, '')
        }));        
        this.stepForm.get('sftpCredsForm').get('awsSecretName').setValue(selectedAwsSecretName);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });  
  }

  populateContactFilterCriteria(sqlFilter: string) {
    this.stepForm.get('listForm').get('contactFilterCriteria').setValue(sqlFilter);    
  }

  populatesDayOfMonth(selectedDayOfMonth: number) {
    this._daysOfMonth = ([...Array(28).keys()].map(x => ++x)); 
    this._dayOfMonthDropdown = this._daysOfMonth.map(x => ({
      value: x, 
      label: x
    }))
    this.stepForm.get('scheduleForm').get('dayOfMonth').setValue(selectedDayOfMonth);
  }

  populateDaysOfWeek(selectedDaysOfWeek: number[]) { 

    this._showDaysOfWeek = selectedDaysOfWeek.length > 0;
    
    this._daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, i) => ({ id: i, name: day }));
    
    this._daysOfWeek.forEach(day => {
      let selected = this._triggerEdit && selectedDaysOfWeek.some(x => x === day.id);      
      this.daysOfWeekArray.push(new UntypedFormControl(selected));
    });
  }

  populateEmails() {

    this._selectedContactsEmail = this._selectedContacts.map(contact => new EmailContactModel({ ContactID: contact.ContactID, 
                                                                                                Email: contact.Email }));

    this.stepForm.get('messageForm').get('contacts').setValue(this._selectedContactsEmail.map(x => x.ContactID));    

  }

  populateFrequency(selectedFrequency: string){
    this._frequencyDropdown = ['Daily', 'Weekly', 'Monthly'].map((day) => ({ label: day, value: day }));
    this.stepForm.get('scheduleForm').get('frequency').setValue(selectedFrequency);
  }

  populateForm () {     

    this.triggerDetailsForm.get('title').setValue(this._triggerToEdit.Title);
    this.triggerDetailsForm.get('triggerID').setValue(this._triggerToEdit.TriggerId);     
        

    if (this._triggerEdit) {        
      this.initLists(this._triggerToEdit.Parameters.TriggerTarget.ListId);
      this.populateContactFilterCriteria(this._triggerToEdit.Parameters.ContactsFilter.SqlFilter);
      this.populateListFields(this._triggerToEdit.Parameters.TriggerTarget.ListId, this._triggerToEdit.Parameters.Extract.FieldIds);
      this.populateTime(this._triggerToEdit.Parameters.Scheduling.DateTime);
      if (this._triggerToEdit.Parameters.NotifyContacts) {          
        this.stepForm.get('emailConfirmationForm').get('messageFormToggle').setValue(true);
        this._selectedListIdForNotifiedContacts = this._triggerToEdit.Parameters.NotifyContacts.ListId;    
        this._selectedNotifiedContactIds = this._triggerToEdit.Parameters.NotifyContacts.ContactIds; 
        this.initListsForNotifiedContacts(this._triggerToEdit.Parameters.NotifyContacts.ListId); 
        this.initMessages(this._triggerToEdit.Parameters.NotifyContacts.MessageId);
      }
      else {
        this.populateMessageFolders(); 
      }   
    } else {
      this.populateListFolders()
      this.populateMessageFolders(); 
    }
              
    this.populateFrequency(this._triggerToEdit.Parameters.Scheduling.Frequency);
    this.populateDaysOfWeek(this._triggerToEdit.Parameters.Scheduling.DaysOfWeek);
    this.populatesDayOfMonth(this._triggerToEdit.Parameters.Scheduling.DayOfMonth);        
    this.populateAwsSecrets(this._triggerToEdit.Parameters.Extract.AwsSecretName, this._awsSecretTagDto);        
}

  populateLists(selectedListFolderId: number, selectedListId?: number) {

    this.listsService.getAllLightLists(selectedListFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._lightLists = this.lightListModelAdapter.adaptArray(executionResultDto.data);          
        this._lightListsDropdown = this._lightLists.map(x => ({
          value: x.ListId, 
          label: x.ListName
        }));
        this.stepForm.get('listForm').get('list').setValue(selectedListId);        
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });    
  }
  
  populateListFolders(selectedFolderId?: number, selectedFolderIdForNotifiedContacts?: number) { 
    this.listFolderService.getAll().subscribe(result => {
      this._listFolders = this.listFolderModelAdapter.adaptArray(result.data);      
       
      this._listFolderDropdown = this._listFolders.map(x => ({
        value: x.FolderID, 
        label: x.FolderName
      }));

      this._listFolderDropdownForNotifiedContacts = this._listFolders.map(x => ({
        value: x.FolderID, 
        label: x.FolderName
      }));

      if (selectedFolderId) {
        this.stepForm.get('listForm').get('listFolder').setValue(selectedFolderId);
      }

      if (selectedFolderIdForNotifiedContacts){
        this.stepForm.get('notifiedContactsForm').get('listFolder').setValue(selectedFolderId);
      }          
    });
  } 

  populateListsForNotifiedContacts(selectedListFolderId: number, selectedListId?: number) {
    this.listsService.getAllLightLists(selectedListFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._lightLists = this.lightListModelAdapter.adaptArray(executionResultDto.data);          
        this._lightListsDropdownForContacts = this._lightLists.map(x => ({
          value: x.ListId, 
          label: x.ListName
      }))      
      this.stepForm.get('notifiedContactsForm').get('list').setValue(selectedListId);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });    
  } 

  populateListFields(selectedListId: number, selectedFieldIds?: number[]) { 

    this.listsService.getAllListFields(selectedListId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        
        this._fields = [];        
        this.fieldsArray.clear();

        this._fields = executionResultDto.data.map(data => ({
          id: data.FieldID, 
          name: data.DisplayName
        }));

        this._fields.forEach((field) => {

          let selected = selectedFieldIds && selectedFieldIds.some(x => x == field.id);        

          this.fieldsArray.push(new UntypedFormControl(selected))
          }
        );

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }); 
  }  

  populateMessages(selectedMessageFolderId, selectedMessageId?) {             
    this.messageService.getLightMessages(selectedMessageFolderId, MessageTypeEnum.email).subscribe(result => {
      this._messages = this.lightweightMessageModelAdapter.adaptArray(result.data);
      this._notifyMessageDropdown = this._messages.map((message: LightweightMessageModel) => ({       
        value: message.MessageID,
        label: message.MessageName
      }));

      this.stepForm.get('messageForm').get('message').setValue(selectedMessageId);       

    });
  }

  populateMessageFolders(selectedMessageFolderId?: number) {   
    this._messageFoldersDropdown = this._messageFolders.map(x => ({
      value: x.id, 
      label: x.name
    })) 
       
    this.stepForm.get('messageForm').get('messageFolder').setValue(selectedMessageFolderId);
  }  

  populateTime(dateTime: Date) {    
    this.stepForm.get('scheduleForm').get('time').setValue(new DatePipe(this._dateCulture).transform(dateTime, 'HH:mm'));
  }

  minSelectedCheckboxes(min: number = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls    
        .map(control => control.value)        
        .reduce((prev, next) => next ? prev + next : prev, 0);
  
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
  
    return validator;
  }

  subscribeToControlValueChanges() {


    this.stepForm.get('scheduleForm').get('frequency').valueChanges.subscribe(val => {
          
      this.stepForm.get('scheduleForm').get('dayOfMonth').setValue(null);             
      this.daysOfWeekArray.controls.map(x => x.patchValue(false))
      
      this._showDayOfMonth = this._showDaysOfWeek = false;
        
      this._showDaysOfWeek = val == "Weekly";
      this._showDayOfMonth = val == "Monthly";      
      
      this.stepForm.get('scheduleForm').get('daysOfWeek').setValidators(this._showDaysOfWeek ? this.minSelectedCheckboxes(1) : null); 
      this.stepForm.get('scheduleForm').get('daysOfWeek').updateValueAndValidity();
  
      this.stepForm.get('scheduleForm').get('dayOfMonth').setValidators(this._showDayOfMonth ? [Validators.required] : null);
      this.stepForm.get('scheduleForm').get('dayOfMonth').updateValueAndValidity();
    });

    this.stepForm.get('emailConfirmationForm').get('messageFormToggle').valueChanges.subscribe(val => {
      this._showNotifyForm = val;      
      this.addRemoveMessageValidation();
      if (!this._showNotifyForm) {
        this.stepForm.get('messageForm').get('messageFolder').setValue(null);
        this.stepForm.get('messageForm').get('message').setValue(null);
        this.clearAll();
      }        
    });

    this.stepForm.get('messageForm').get('message').valueChanges.subscribe((val: any) => {
      if (val) {      
        this._selectedMessageId = val;
      }
    });    

    this.stepForm.get('notifiedContactsForm').get('list').valueChanges.subscribe(val => {         
      if (val) { 
        if (this._selectContactsModalOpened) {          
          this._selectedNotifiedContactIds = [];          
        }        
        this._selectedListIdForNotifiedContacts = val;    
        this.getContacts();        
      }   
    }); 

  }

  saveAwsSecret() {

    const stepForm = this.stepForm.get('sftpCredsForm');      

    const awsSecretSftpDto = new AwsSecretSftpDto({         
        awsSecretName: stepForm.get('awsSecretName').value ? stepForm.get('awsSecretName').value : `${this._awsSecretTagDto.value}-${stepForm.get('username').value}`, 
        host: stepForm.get('host').value, 
        username: stepForm.get('username').value, 
        password: stepForm.get('password').value,
        tag: this._awsSecretTagDto
      });      

    this._savingAwsSecret = true;    

    this.triggersService.createUpdateAwsSecret(awsSecretSftpDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        
        //allow a second for AWS to create new secret.
        window.setTimeout(() => {           
          this.notificationService.showSuccess(executionResultDto.message);            
          this.populateAwsSecrets(awsSecretSftpDto.awsSecretName, this._awsSecretTagDto);
          this._savingAwsSecret = this._showAwsSecretFields = false;           
        }, 1000);
        
      } else {
        this._savingAwsSecret = false;
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  saveExtract() {

    this._triggerToEdit.Title = this.triggerDetailsForm.get('title').value; 
    this._triggerToEdit.WorkflowId = this._workflowId;  
    
    const parameters = this._triggerToEdit.Parameters;
   
    parameters.TriggerTarget.ListId = this.stepForm.get('listForm').get('list').value;
    parameters.ContactsFilter.SqlFilter = this.stepForm.get('listForm').get('contactFilterCriteria').value;
    parameters.Scheduling.DayOfMonth = this.stepForm.get('scheduleForm').get('dayOfMonth').value;
    parameters.Scheduling.DaysOfWeek = this.getSelectedDayIds(); 
    parameters.Scheduling.DateTime = this.stepForm.get('scheduleForm').get('time').value;
    parameters.Scheduling.Frequency = this.stepForm.get('scheduleForm').get('frequency').value;
    parameters.Scheduling.SchedulingType = SchedulingTypeEnum.Extract;

    parameters.Extract.FieldIds = this.getSelectedFieldIds();
    parameters.Extract.AwsSecretName = this.stepForm.get('sftpCredsForm').get('awsSecretName').value;                                    

    if (this._showNotifyForm) {
      parameters.NotifyContacts = this.notifyContactsActionInfoAdapter.createEmpty(); 
      parameters.NotifyContacts.ContactIds = this.stepForm.get('messageForm').get('contacts').value;
      parameters.NotifyContacts.ListId = this.stepForm.get('notifiedContactsForm').get('list').value;
      parameters.NotifyContacts.MessageId = this.stepForm.get('messageForm').get('message').value;
      parameters.NotifyContacts.MessageType = MessageTypeEnum.email;
    }
    else {
      parameters.NotifyContacts = null;
    }

    this._triggerToEdit.Parameters = parameters;    

    if (!this._triggerEdit) {
      this.triggersService.createTrigger(this._triggerToEdit).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowId]);
      });
    } else {
      this.triggersService.editTrigger(this._triggerToEdit).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowId]);
      });
    }
  }

  showAddAwsSecretFields(show: boolean) {
    this._showAwsSecretFields = show;
    this.stepForm.get('sftpCredsForm').get('awsSecretName').setValue(null);
    this.addRemoveSftpCredsValidation();
  } 
  
  

  showMessagePreviewModal(messageId: number) {
    this.emailMessageService.getPreviewMessage(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._messagePreviewHtml = executionResultDto.data.HtmlContent;
        this._emailPreviewModal.show();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }
  
  showNotifyContactsMessagePreviewModal() {     
    this.showMessagePreviewModal(this._selectedMessageId);
  } 
  
  showQueryBuilder(){
    var listId = this.stepForm.get('listForm').get('list').value;
    if (!!listId) {      
      this.queryBuilder.populateQueryFilterList(this._pageTitle);
      this.initQueryBuilder(listId, 'contacts', this.stepForm.get('listForm').get('contactFilterCriteria').value);    
      this.queryBuilderModal.show();
    }
  }
  
  initQueryBuilder(listID: number, source: string, filter: string, isReset?: boolean) {
    this.queryBuilder.getListFields(listID, source, undefined, isReset);
  }

  resetQueryBuilderData(isReset) {
    this.stepForm.get('listForm').get('contactFilterCriteria').setValue('');

    this.queryBuilder.populateQueryFilterList(this._pageTitle);
    this.initQueryBuilder(this.stepForm.get('listForm').get('list').value, 'contacts', '', isReset);
  }
  
}
