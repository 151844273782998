import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, UntypedFormArray, } from '@angular/forms';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { ActionRelatesComponent } from './action-relates/action-relates.component';
import { ActionsToPerformComponent } from './actions-to-perform/actions-to-perform.component';
import { PreviewDataMessageComponent } from './preview-data-message/preview-data-message.component';
import { MdbStepperComponent } from 'ng-uikit-pro-standard';
import { TriggerModel } from 'src/app/_models/messaging/automation/triggers/trigger-model';
import { TriggerModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-model.adapter';
import { TriggerTargetModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-target-model.adapter';
import { SendMessageActionInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-message-action-info.adapter';
import { TriggerSchedulingModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-scheduling-model.adapter';
import { TriggerExecutionDataModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-execution-data-model.adapter';
import { TriggerTargetModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-target-model';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EventTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/event-type.enum';
import { SendMessageActionInfo } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-message-action-info';
import { TriggerSchedulingModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-scheduling-model';
import { SchedulingTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/scheduling-type.enum';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DatePipe, Location } from '@angular/common';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { NotifyContactsActionInfo } from 'src/app/_models/messaging/automation/triggers/trigger-params/notify-contacts-action-info';
import { NotifyContactsActionInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/notify-contacts-action-info.adapter';
import { UpdateContactActionInfo } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info';
import { LightweightMessageModel } from 'src/app/_models/messaging/messages/lightweight-message-model';
import { LightweightMessageModelAdapter } from 'src/app/_models/messaging/messages/lightweight-message-model.adapter';
import { EventInfoModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/event-info-model';
import { EventInfoModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/event-info-model.adapter';
import { UpdateContactActionInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info.adapter';
import { GetContactsDto } from 'src/app/_models/messaging/automation/triggers/trigger-params/get-contacts-dto';
import { GetContactsDtoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/get-contacts-dto.adapter';
import { EmailContactModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model';
import { EmailContactModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model.adapter';
import { UpdateContactValuesComponent } from './actions-to-perform/update-contact-values/update-contact-values.component';
import { ContactsFilterModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/contacts-filter-model';
import { TriggerComponent } from './trigger/trigger.component';
import { QueryBuilderStorageService } from 'src/app/_services/query-builder-filters/query-builder-storage.service';
import { SendViaApiInfo } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-via-api-info';
import { SendViaApiInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-via-api-info-adapter';
import { SendViaApiFieldMapping } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-via-api-field-mapping';

@Component({
  selector: 'app-create-edit-trigger',
  templateUrl: './create-edit-trigger.component.html',
  styleUrls: ['./create-edit-trigger.component.scss'],
  providers: [UpdateContactValuesComponent]
})
export class CreateEditTriggerComponent implements OnInit, AfterViewInit {

  @ViewChild('actionRelatesComponent', { static: true }) actionRelatesComponent: ActionRelatesComponent;
  @ViewChild('actionsToPerformComponent', { static: true }) actionsToPerformComponent: ActionsToPerformComponent;
  @ViewChild('triggerComponent', { static: true }) actionsTriggerComponent: TriggerComponent;
  @ViewChild('previewDataMessageComponent', { static: true }) previewDataMessageComponent: PreviewDataMessageComponent;
  @ViewChild('stepper', { static: true }) stepper: MdbStepperComponent;
  _dateCulture = 'en-GB';

  triggerDetailsForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, Validators.required),
    triggerID: new UntypedFormControl(0),
  });

  stepForm: UntypedFormGroup = new UntypedFormGroup({
    actionRelatesFormGroup: new UntypedFormGroup({
      triggerActionType: new UntypedFormControl('list'),
      listFolder: new UntypedFormControl(),
      list: new UntypedFormControl('', Validators.required),
      messageType: new UntypedFormControl(MessageTypeEnum.email),
      messageFolder: new UntypedFormControl(),
      message: new UntypedFormControl(),
    }),
    triggerFormGroup: new UntypedFormGroup({
      trigger: new UntypedFormControl('', Validators.required),
      messageLinkId: new UntypedFormControl(),
      targetUrl: new UntypedFormControl(),
      form: new UntypedFormControl(),
      timeBasedWCC: new UntypedFormControl(),
      timeBasedWCCFormGroup: new UntypedFormGroup({
        contactCriteria: new UntypedFormControl(),
        time: new UntypedFormControl(),
      }),
      timeBasedFormGroup: new UntypedFormGroup({
        field: new UntypedFormControl(),
        frequency: new UntypedFormControl({ value: 1, disabled: true }),
        numberOf: new UntypedFormControl(),
        daysWeeksMonths: new UntypedFormControl(),
        beforeAfter: new UntypedFormControl(),
        time: new UntypedFormControl(),
      }),
      timeBasedOneOffSendFormGroup: new UntypedFormGroup({
        oneOffSendDateTime: new UntypedFormControl(),
      }),
      contactFilterCriteria: new UntypedFormControl(),
    }),
    actionsToPerformFormGroup: new UntypedFormGroup({
      sendContactMessageToggle: new UntypedFormControl(false),
      sendContactMessage: new UntypedFormGroup({
        sendMessageType: new UntypedFormControl(MessageTypeEnum.email),
        messageFolder: new UntypedFormControl(),
        message: new UntypedFormControl(),
      }),
      notifyContactMessageToggle: new UntypedFormControl(false),
      notifyContactMessage: new UntypedFormGroup({
        contacts: new UntypedFormControl(),
        listFolder: new UntypedFormControl(),
        list: new UntypedFormControl(),
        messageType: new UntypedFormControl(MessageTypeEnum.email),
        messageFolder: new UntypedFormControl(),
        message: new UntypedFormControl(),
      }),
      updateValuesToggle: new UntypedFormControl(false),
      updateValues: new UntypedFormGroup({
        updateContactData: new UntypedFormControl(),
      }),
      sendViaApiToggle: new UntypedFormControl(false),
      sendViaApiForm: new UntypedFormGroup({
        triggerApi: new UntypedFormControl(),
        system: new UntypedFormControl(),
        location: new UntypedFormControl(),
        apiSection: new UntypedFormControl(),
        apiIntegrationEndpoint: new UntypedFormControl(),
        listField: new UntypedFormControl(),
      })
    }, [this.actionsToPerformValidator]),
    timingFormGroup: new UntypedFormGroup({
      timingType: new UntypedFormControl('', [Validators.required]),
      periodOfTimeFormGroup: new UntypedFormGroup({
        numberOf: new UntypedFormControl('', [Validators.min(0), Validators.max(500)]),
        minsHoursDaysWeeks: new UntypedFormControl(),
        time: new UntypedFormControl(),
      })
    })
  });

  _titleText: string = 'Create Step';
  _triggerEdit: Boolean = false;
  _messageFolders: Array<FolderDto> = [];
  _lightLists: Array<LightListModel> = [];
  _listFolders: Array<ListFolderModel> = [];
  _showTimingStep: Boolean = true;
  _showPreviewStep: Boolean = true;
  _workflowID: number;
  _triggerToEdit: TriggerModel;
  _eventTypeEnum: EventTypeEnum;
  _updatingContactValues: Boolean = false;
  _selectedListID: number;
  _contactFilter: string;
  _selectedMessageID: number;
  _selectedListFolder: number;

  constructor(
    private messageFolderDtoAdapter: FolderDtoAdapter,
    private listFolderService: ListFolderService,
    private listFolderModelAdapter: ListFolderModelAdapter,
    private triggerModelAdapter: TriggerModelAdapter,
    private triggerTargetModelAdapter: TriggerTargetModelAdapter,
    private sendMessageActionInfoAdapter: SendMessageActionInfoAdapter,
    private triggerSchedulingModelAdapter: TriggerSchedulingModelAdapter,
    private notifyContactsActionInfoAdapter: NotifyContactsActionInfoAdapter,
    private triggerExecutionDataModelAdapter: TriggerExecutionDataModelAdapter,
    private triggersService: TriggersService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location,
    private router: Router,
    private lightweightMessageModelAdapter: LightweightMessageModelAdapter,
    private eventInfoModelAdapter: EventInfoModelAdapter,
    private updateContactActionInfoAdapter: UpdateContactActionInfoAdapter,
    private getContactsDtoAdapter: GetContactsDtoAdapter,
    private emailContactModelAdapter: EmailContactModelAdapter,
    private updateContactValuesComponent: UpdateContactValuesComponent,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private queryBuilderStorageService: QueryBuilderStorageService,
    private sendViaApiInfoAdapter: SendViaApiInfoAdapter,
  ) {
  }

  ngOnInit() {
    this.getRouteInfo();
    this.getListData();
    this.onChanges();
    this.actionRelatesComponent.listChanged.subscribe(() => {
      this.actionsTriggerComponent.triggerFormGroup.get('contactFilterCriteria').setValue("");
    });
  }

  ngAfterViewInit() {
    this.route.params.subscribe(params => {
      if (params.triggerID) {
        this.initForEdit();
        this._titleText = 'Edit Step';
      }
    });
    this.cdr.detectChanges();
  }

  getRouteInfo() {
    this.route.params.subscribe(params => {
      this._workflowID = params.contactPlanId;
      if (params.triggerID) {
        this._triggerEdit = true;
      }
    });

    this.route.data.subscribe(data => {
      const messageFolders: Array<FolderDto> = this.messageFolderDtoAdapter.adaptArray(data.messageFolders.data);
      this.actionRelatesComponent.populateMessageFolderDropdown(messageFolders);
      this.actionsToPerformComponent.populateMessageFolderDropdown(messageFolders);
    });
  }

  getListData() {
    // Get List Folders
    this.listFolderService.getAll().subscribe(result => {
      this._listFolders = this.listFolderModelAdapter.adaptArray(result.data);
      this.actionRelatesComponent.populateListFoldersDropdown();
      this.actionsToPerformComponent.selectContactsComponent.populateListFoldersDropdown();
    }, error => {
      console.log(error);
    });
  }

  onChanges() {

    //Get timeBased Trigger Form Control & Send Contact Message Toggle Form Control
    var timeBasedTrigger = this.stepForm.controls.triggerFormGroup.get('trigger');
    var sendContactMessageToggle = this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessageToggle');
    var listIDFC = this.stepForm.controls.actionRelatesFormGroup.get('list');
    var contactCriteriaFC = this.stepForm.controls.triggerFormGroup.get('contactFilterCriteria');

    //Subscribe to List and Contact Filters for Previewing Contacts
    this.stepForm.controls.actionRelatesFormGroup.get('listFolder').valueChanges.subscribe(val => this._selectedListFolder = val);

    //Subscribe to Message ID value to ensure right email is being previewed
    this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('message').valueChanges.subscribe(val => this._selectedMessageID = val);

    // Subscribe to event and ensure conditions are met before opening the preview message/contacts step

    timeBasedTrigger.valueChanges.subscribe(val => {
      if (val == 18 && sendContactMessageToggle.value == true && listIDFC.value != undefined) {
        this._showPreviewStep = true;
      } else this._showPreviewStep = false;

      this.updateSteps();
    });

    sendContactMessageToggle.valueChanges.subscribe(val => {
      if (val == true && timeBasedTrigger.value == 18 && listIDFC.value != undefined) {
        this._showPreviewStep = true;
      } else this._showPreviewStep = false;

      this.updateSteps();
    });

    listIDFC.valueChanges.subscribe(val => {
      if (sendContactMessageToggle.value == true && timeBasedTrigger.value == 18 && val != undefined) {
        this._showPreviewStep = true;
      } else this._showPreviewStep = false;

      this._selectedListID = val;

      this.updateSteps();
    });

    contactCriteriaFC.valueChanges.subscribe(val => {
      if (sendContactMessageToggle.value == true && timeBasedTrigger.value == 18 && listIDFC.value != undefined) {
        this._showPreviewStep = true;
      } else this._showPreviewStep = false;

      this._contactFilter = val;

      this.updateSteps();
    });
  }

  updateSteps() {
    if (this.stepper.steps) {
      const activeStepIndex = this.stepper.activeStepIndex;
      this.stepper.previous();
      this.stepper.next();
      this.stepper.setNewActiveStep(activeStepIndex);
    }
  }

  getMessageByID(messageID: number, messageType: MessageTypeEnum, form: AbstractControl) {
    var message: LightweightMessageModel;

    this.triggersService.getLightMessage(messageID, messageType).subscribe(result => {

      message = this.lightweightMessageModelAdapter.adapt(result.data);
      switch (form) {
        case this.stepForm.get('actionRelatesFormGroup'):
          this.stepForm.controls.actionRelatesFormGroup.get('messageFolder').setValue(message.FolderID);
          break;
        case this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage'):
          this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('messageFolder').setValue(message.FolderID);
          break;
        case this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage'):
          this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('messageFolder').setValue(message.FolderID);
          break;
        default:
          break;
      }
    });
  }

  showValidationError(forms: Array<string>) {
    switch (forms.length) {
      case 2:
        if (this.stepForm.get(forms[0]).get(forms[1]).invalid &&
          this.stepForm.get(forms[0]).get(forms[1]).touched) {
          return true;
        } else {
          return false;
        }
        break;
      case 3:
        if (this.stepForm.get(forms[0]).get(forms[1]).get(forms[2]).invalid &&
          this.stepForm.get(forms[0]).get(forms[1]).get(forms[2]).touched) {
          return true;
        } else {
          return false;
        }
        break;
    }
  }

  actionsToPerformValidator(control: AbstractControl) {
    var sendContactMessageToggle = control.get('sendContactMessageToggle').value;
    var notifyContactMessageToggle = control.get('notifyContactMessageToggle').value;
    var updateValuesToggle = control.get('updateValuesToggle').value;
    var sendViaApiToggle = control.get('sendViaApiToggle').value;
    if (sendContactMessageToggle === false
      && notifyContactMessageToggle === false
      && updateValuesToggle === false
      && sendViaApiToggle === false) {
      return { 'actionsToPerformError': true };
    } else {
      return null;
    }
  }

  showHideTimingStep(showHideValue: Boolean) {
    if (showHideValue) {
      this.stepForm.controls.timingFormGroup.get('timingType').setValidators([Validators.required]);
    } else {
      this.stepForm.controls.timingFormGroup.get('timingType').setValidators(null);
    }
    this.stepForm.get('timingFormGroup').get('timingType').updateValueAndValidity();
    this._showTimingStep = showHideValue;

    this.updateSteps();
  }

  initForEdit() {
    this.route.data.subscribe(data => {
      var triggerToEdit: TriggerModel = this.triggerModelAdapter.createEmpty();
      if (data.trigger.data) {
        triggerToEdit = this.triggerModelAdapter.adapt(data.trigger.data);
      } else {
        triggerToEdit = this.triggerModelAdapter.adapt(data.trigger.triggerData.data);
      }
      this.populateStepForm(triggerToEdit);
    });
  }

  // Function to start populating the whole form from TriggerData from the API on an edit.
  populateStepForm(triggerToEdit: TriggerModel) {
    this.populateDetailsFormGroup(triggerToEdit);
    this.populateActionRelatesFormGroup(triggerToEdit.Parameters.TriggerTarget);
    this.populateTriggerFormGroup(triggerToEdit, triggerToEdit.Parameters.Scheduling);
    this.populateActionsToPerformFormGroup(
      triggerToEdit.Parameters.SendMessage,
      triggerToEdit.Parameters.NotifyContacts,
      triggerToEdit.Parameters.UpdateContact,
      triggerToEdit.Parameters.SendViaApi,
      triggerToEdit.Parameters.TriggerTarget
    );
    this.populateTimingFormGroup(triggerToEdit.Parameters.Scheduling);
    this.stepper.linear = false;
  }

  // Populate simple Trigger Details, Title, TriggerID, etc...
  populateDetailsFormGroup(trigger: TriggerModel) {
    this.triggerDetailsForm.get('title').setValue(trigger.Title);
    this.triggerDetailsForm.get('triggerID').setValue(trigger.TriggerId);
  }

  // Populate the Action Relates Form Group on an EditInit.
  populateActionRelatesFormGroup(triggerTarget: TriggerTargetModel) {
    this.stepForm.controls.actionRelatesFormGroup.get('triggerActionType').setValue('list');
    this.actionRelatesComponent.setListFolder(triggerTarget.ListId);
    this.stepForm.controls.actionRelatesFormGroup.get('list').setValue(triggerTarget.ListId);
    if (triggerTarget.SubjectType === EventTypeEnum.Message) {
      this.stepForm.controls.actionRelatesFormGroup.get('triggerActionType').setValue('message');
      this.stepForm.controls.actionRelatesFormGroup.get('messageType').setValue(triggerTarget.MessageType);
      this.getMessageByID(triggerTarget.MessageId, triggerTarget.MessageType, this.stepForm.controls.actionRelatesFormGroup);
      this.stepForm.controls.actionRelatesFormGroup.get('message').setValue(triggerTarget.MessageId);
    }
  }

  // Populate the Trigger Form Group on an EditInit.
  populateTriggerFormGroup(triggerModel: TriggerModel, triggerScheduling: TriggerSchedulingModel) {
    const trigger = this.getTriggerEventInfo(null, triggerModel.Parameters.TriggerTarget.SubjectEventType, triggerModel.Parameters.TriggerTarget.SubjectType);
    this.stepForm.controls.triggerFormGroup.get('trigger').setValue(trigger.EventID);

    this.stepForm.controls.triggerFormGroup.get('contactFilterCriteria').setValue(triggerModel.Parameters.ContactsFilter.SqlFilter);

    if (trigger.EventID === 1) {
      this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('field').setValue(triggerModel.Parameters.TriggerTarget.FieldName);

      this.calcFromSeconds(triggerScheduling.DelayInSeconds, this.stepForm.controls.triggerFormGroup);
      if (triggerScheduling.MonthDelay !== 0) {
        this.calcFromMonths(triggerScheduling.MonthDelay);
      }
      const time = this.calcTimeFromDate(triggerScheduling.DateTime);
      this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('time').setValue(time);
    } else if (trigger.EventID === 2) {
      const time = this.calcTimeFromDate(triggerScheduling.DateTime);
      this.stepForm.controls.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').setValue(triggerModel.Parameters.ContactsFilter.MessageCodeStepId);
      this.stepForm.controls.triggerFormGroup.get('timeBasedWCCFormGroup').get('time').setValue(time);
    } else if (trigger.EventID === 18) {
      this.stepForm.controls.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').setValue(triggerScheduling.DateTime.toString().slice(0, 16));
    }
    else if (trigger.EventID === 12 || trigger.EventID === 14 || trigger.EventID === 16) {
      this.stepForm.controls.triggerFormGroup.get('form').setValue(triggerModel.Parameters.TriggerTarget.WebFormId);
    } else if (trigger.EventID === 5) {
      if (!this.actionsTriggerComponent._messagesLinksDropdownPopulated) {
        this.actionsTriggerComponent.populateMessageLinksDropdown(triggerModel.Parameters.TriggerTarget.MessageId);
      }
      this.stepForm.controls.triggerFormGroup.get('messageLinkId').setValue(triggerModel.Parameters.TriggerTarget.MessageLinkId);
    }
    else if (trigger.EventID === 20) {
      this.stepForm.controls.triggerFormGroup.get('targetUrl').setValue(triggerModel.Parameters.TriggerTarget.TargetUrl);
    }
  }

  // Populate Actions To Perform Form Group on an EditInit.
  populateActionsToPerformFormGroup(
    sendMessage: SendMessageActionInfo,
    notifyContacts: NotifyContactsActionInfo,
    updateContacts: UpdateContactActionInfo,
    sendViaApi: SendViaApiInfo,
    triggerTarget:  TriggerTargetModel) {
    if (sendMessage) {
      this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessageToggle').setValue(true);
      this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('sendMessageType').setValue(sendMessage.MessageType);
      this.getMessageByID(sendMessage.MessageId, sendMessage.MessageType, this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage'));
      this.stepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('message').setValue(sendMessage.MessageId);
    }
   
    if (notifyContacts) {

      if (notifyContacts.ContactIds.length > 0) {
        this.actionsToPerformComponent.selectedContactIds = notifyContacts.ContactIds;
        this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('list').setValue(notifyContacts.ListId);
        this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('contacts').setValue(notifyContacts.ContactIds);
        if (notifyContacts.ContactIds.length > 0) {
          this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('contacts').setValue(notifyContacts.ContactIds);
          this.getContactsByID(notifyContacts);
        }
      }
      this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessageToggle').setValue(true);
      this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('messageType').setValue(notifyContacts.MessageType);
      this.getMessageByID(notifyContacts.MessageId, notifyContacts.MessageType, this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage'));
      this.stepForm.controls.actionsToPerformFormGroup.get('notifyContactMessage').get('message').setValue(notifyContacts.MessageId);
    }
    if (updateContacts.Changes.length > 0) {
      this.stepForm.controls.actionsToPerformFormGroup.get('updateValuesToggle').setValue(true);
      this._updatingContactValues = true;
      this.populateUpdateContactValuesFormArray(updateContacts);
    }

    if (sendViaApi) {
      this.stepForm.controls.actionsToPerformFormGroup.get('sendViaApiToggle').setValue(true);
      this.stepForm.controls.actionsToPerformFormGroup.get('sendViaApiForm').get('triggerApi').setValue(sendViaApi.ApiName);
      this.stepForm.controls.actionsToPerformFormGroup.get('sendViaApiForm').get('system').setValue(sendViaApi.SchemaId);
      this.stepForm.controls.actionsToPerformFormGroup.get('sendViaApiForm').get('location').setValue(sendViaApi.LocationName);
      this.stepForm.controls.actionsToPerformFormGroup.get('sendViaApiForm').get('apiSection').setValue(sendViaApi.EndpointName);
      this.actionsToPerformComponent.populateSavedApiIntegrationFieldsMapping(sendViaApi.SendViaApiFieldMappings);
    }
  }

  // Populate Timing Form Group on an EditInit.
  populateTimingFormGroup(timing: TriggerSchedulingModel) {
    if (this._showTimingStep) {
      switch (timing.SchedulingType) {
        case SchedulingTypeEnum.Immediately:
          this.stepForm.controls.timingFormGroup.get('timingType').setValue('immediately');
          break;
        case SchedulingTypeEnum.Delayed:
        case SchedulingTypeEnum.SpecifiedWithDelay:
          this.stepForm.controls.timingFormGroup.get('timingType').setValue('periodOfTime');
          this.calcFromSeconds(timing.DelayInSeconds, this.stepForm.controls.timingFormGroup);
          if (timing.DateTime) {
            const time = this.calcTimeFromDate(timing.DateTime);
            this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('time').setValue(time);
          }
          break;
      }
    }
  }

  populateUpdateContactValuesFormArray(updateContacts: UpdateContactActionInfo) {
    const component = this.updateContactValuesComponent;
    updateContacts.Changes.forEach((change, index) => {
      component.addField();
      this.triggersService.fields.controls[index].get('argumentFromEdit').setValue(change.Argument);
      this.triggersService.fields.controls[index].get('updateType').setValue(change.UpdateTypeName);
      this.triggersService.fields.controls[index].get('name').setValue(change.Name);
    });
    this.actionsToPerformComponent.populateUpdateContactValues();
  }

  getTriggerEventInfo(eventID?: number, subjectEventType?, subjectType?): EventInfoModel {
    var triggers: Array<EventInfoModel>;
    var trigger: EventInfoModel;

    this.route.data.subscribe(data => {
      triggers = this.eventInfoModelAdapter.adaptArray(data.triggerEvents.data);
      triggers = triggers.filter(x => x.SubjectType != EventTypeEnum.Extract);
    });
    if (eventID) {
      trigger = triggers.find(t => t.EventID === eventID);
    } else {
      trigger = triggers.find(t => t.SubjectEventType === subjectEventType &&
        t.SubjectType === subjectType);
    }
    return trigger;
  }

  getContactsByID(notifyContacts: NotifyContactsActionInfo) {
    var getContactsDto: GetContactsDto = this.getContactsDtoAdapter.createEmpty();
    getContactsDto.ListID = notifyContacts.ListId;
    getContactsDto.ContactIDs = notifyContacts.ContactIds;

    this.triggersService.getContactsByID(getContactsDto).subscribe(result => {
      const contacts: Array<EmailContactModel> = this.emailContactModelAdapter.adaptArray(result.data);
      contacts.forEach(contact => {
        this.actionsToPerformComponent.selectContactsComponent._selectedContactsEmail.push(contact);
      });
    });
  }

  submitTriggerForm() {
    this.queryBuilderStorageService.ClearSQLFilterFromLocalStorage();

    var trigger: TriggerModel;
    trigger = this.triggerModelAdapter.createEmpty();
    trigger = this.createEmptyTrigger(trigger);
    trigger = this.populateTriggerData(trigger);
    if (!this._triggerEdit) {
      this.triggersService.createTrigger(trigger).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowID]);
      });
    } else {
      this.triggersService.editTrigger(trigger).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowID]);
      });
    }
  }

  createEmptyTrigger(trigger: TriggerModel) {
    trigger.Parameters = this.triggerExecutionDataModelAdapter.createEmpty();
    trigger.Parameters.TriggerTarget = this.triggerTargetModelAdapter.createEmpty();
    trigger.Parameters.Scheduling = this.triggerSchedulingModelAdapter.createEmpty();
    return trigger;
  }

  populateTriggerContactsFilter(contactsFilter: ContactsFilterModel) {
    const messageCodeStepId = this.stepForm.get('triggerFormGroup').get('timeBasedWCCFormGroup').get('contactCriteria').value;
    contactsFilter.MessageCodeStepId = messageCodeStepId ? messageCodeStepId : 0;
    contactsFilter.SqlFilter = this.stepForm.get('triggerFormGroup').get('contactFilterCriteria').value;
    return contactsFilter;
  }

  populateTriggerData(trigger: TriggerModel) {
    trigger = this.populateTriggerDetails(trigger);
    trigger.Parameters.TriggerTarget = this.populateTriggerTarget(trigger.Parameters.TriggerTarget);
    trigger.Parameters.ContactsFilter = this.populateTriggerContactsFilter(trigger.Parameters.ContactsFilter);

    if (this.stepForm.get('actionsToPerformFormGroup').get('sendContactMessageToggle').value) {
      trigger.Parameters.SendMessage = this.sendMessageActionInfoAdapter.createEmpty();
      trigger.Parameters.SendMessage = this.populateTriggerSendMessage(trigger.Parameters.SendMessage);
    }
    if (this.stepForm.get('actionsToPerformFormGroup').get('notifyContactMessageToggle').value) {
      trigger.Parameters.NotifyContacts = this.notifyContactsActionInfoAdapter.createEmpty();
      trigger.Parameters.NotifyContacts = this.populateTriggerNotifyContacts(trigger.Parameters.NotifyContacts);
    }
    if (this.stepForm.get('actionsToPerformFormGroup').get('updateValuesToggle').value) {
      trigger.Parameters.UpdateContact = this.updateContactActionInfoAdapter.createEmpty();
      trigger.Parameters.UpdateContact = this.populateTriggerUpdateContact(trigger.Parameters.UpdateContact);
    }
    if (this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiToggle').value) {
      trigger.Parameters.SendViaApi = this.sendViaApiInfoAdapter.createEmpty();
      trigger.Parameters.SendViaApi = this.populateTriggerSendViaApi(trigger.Parameters.SendViaApi);
    }
    trigger.Parameters.Scheduling = this.populateTriggerScheduling(trigger.Parameters.Scheduling);
    return trigger;
  }

  populateTriggerDetails(trigger: TriggerModel) {
    const title = this.triggerDetailsForm.get('title').value;
    if (title) {
      trigger.Title = title;
    }
    trigger.TriggerId = this.triggerDetailsForm.get('triggerID').value;
    trigger.WorkflowId = this._workflowID;
    return trigger;
  }

  // populate the target parameters of the TriggerModel
  populateTriggerTarget(triggerTarget: TriggerTargetModel) {
    const trigger = this.getTriggerEventInfo(this.stepForm.get('triggerFormGroup').get('trigger').value);
    triggerTarget.EventName = trigger.DisplayName;
    triggerTarget.ListId = this.stepForm.get('actionRelatesFormGroup').get('list').value;
    triggerTarget.SubjectEventType = trigger.SubjectEventType;
    triggerTarget.SubjectType = trigger.SubjectType;

    if (this.stepForm.get('actionRelatesFormGroup').get('triggerActionType').value === 'message') {
      triggerTarget.MessageId = this.stepForm.get('actionRelatesFormGroup').get('message').value;
      triggerTarget.MessageType = this.stepForm.get('actionRelatesFormGroup').get('messageType').value;
      triggerTarget.MessageLinkId = this.stepForm.get('triggerFormGroup').get('messageLinkId').value;
    } else if (triggerTarget.SubjectType === EventTypeEnum.List &&
      (triggerTarget.SubjectEventType === 6 ||
        triggerTarget.SubjectEventType === 8 ||
        triggerTarget.SubjectEventType === 10 || triggerTarget.SubjectEventType === 13)) {
      triggerTarget.WebFormId = this.stepForm.get('triggerFormGroup').get('form').value;
      triggerTarget.TargetUrl = this.stepForm.get('triggerFormGroup').get('targetUrl').value;
    } else if (triggerTarget.SubjectType === EventTypeEnum.ListField) {
      triggerTarget.FieldName = this.stepForm.get('triggerFormGroup').get('timeBasedFormGroup').get('field').value;
    }
    return triggerTarget;
  }

  // populate the send message parameters of the TriggerModel
  populateTriggerSendMessage(sendMessage: SendMessageActionInfo) {
    sendMessage.MessageId = this.stepForm.get('actionsToPerformFormGroup').get('sendContactMessage').get('message').value;
    sendMessage.MessageType = this.stepForm.get('actionsToPerformFormGroup').get('sendContactMessage').get('sendMessageType').value;

    return sendMessage;
  }

  populateTriggerSendViaApi(sendViaApi: SendViaApiInfo) {
    sendViaApi.ApiName = this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('triggerApi').value;
    sendViaApi.SchemaId = this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('system').value;
    sendViaApi.LocationName = this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('location').value;
    sendViaApi.EndpointName = this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('apiSection').value;
    if (this.actionsToPerformComponent.apiIntegrationEndpointFieldsMappings != null && this.actionsToPerformComponent.apiIntegrationEndpointFieldsMappings.length > 0){
      sendViaApi.SendViaApiFieldMappings = this.actionsToPerformComponent.apiIntegrationEndpointFieldsMappings
            .map(element =>
              new SendViaApiFieldMapping({ ApiFieldName: element.apiValue, ContactFieldName: element.voiceboxFieldName }));
    }
    else if (this.actionsToPerformComponent.sendViaApiFieldMappings != null && this.actionsToPerformComponent.sendViaApiFieldMappings.length > 0){
      sendViaApi.SendViaApiFieldMappings = this.actionsToPerformComponent.sendViaApiFieldMappings;
    }
    
    if (this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('apiIntegrationEndpoint').value
      && this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('listField').value) {
        if(sendViaApi.SendViaApiFieldMappings == null){
          sendViaApi.SendViaApiFieldMappings=[];
        }
      sendViaApi.SendViaApiFieldMappings.push(
        new SendViaApiFieldMapping(
          {
            ApiFieldName: this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('apiIntegrationEndpoint').value,
            ContactFieldName: this.stepForm.get('actionsToPerformFormGroup').get('sendViaApiForm').get('listField').value
          }))
    }
    return sendViaApi;
  }

  // populate the notifying of contacts of the TriggerModel
  populateTriggerNotifyContacts(notifyContacts: NotifyContactsActionInfo) {
    notifyContacts.ContactIds = this.stepForm.get('actionsToPerformFormGroup').get('notifyContactMessage').get('contacts').value;
    notifyContacts.ListId = this.stepForm.get('actionsToPerformFormGroup').get('notifyContactMessage').get('list').value;
    notifyContacts.MessageId = this.stepForm.get('actionsToPerformFormGroup').get('notifyContactMessage').get('message').value;
    notifyContacts.MessageType = this.stepForm.get('actionsToPerformFormGroup').get('notifyContactMessage').get('messageType').value;

    return notifyContacts;
  }

  // populate the update contact part of the TriggerModel
  populateTriggerUpdateContact(updateContact: UpdateContactActionInfo): UpdateContactActionInfo {
    updateContact.Changes = this.actionsToPerformComponent.updateContactValues;
    return updateContact;
  }

  // populate the trigger scheduling of the TriggerModel
  populateTriggerScheduling(scheduling: TriggerSchedulingModel) {
    // check to see if we're doing a time based trigger, and handle the scheduling from the time based form params.    
    if (this.stepForm.get('triggerFormGroup').get('trigger').value === 1) {
      var numOfSchedulingUnit = this.stepForm.get('triggerFormGroup').get('timeBasedFormGroup').get('numberOf').value;

      const schedulingUnit = this.stepForm.get('triggerFormGroup').get('timeBasedFormGroup').get('daysWeeksMonths').value;
      const beforeAfter = this.stepForm.get('triggerFormGroup').get('timeBasedFormGroup').get('beforeAfter').value;
      scheduling.SchedulingType = SchedulingTypeEnum.SpecifiedWithDelay;
      scheduling.DateTime = this.stepForm.get('triggerFormGroup').get('timeBasedFormGroup').get('time').value;
      if (schedulingUnit === 3 || schedulingUnit === 4) {
        scheduling.DelayInSeconds = this.calcSeconds(numOfSchedulingUnit, schedulingUnit, beforeAfter);
      } else {
        if (beforeAfter === 1) {
          numOfSchedulingUnit = -Math.abs(numOfSchedulingUnit);
        }
        scheduling.MonthDelay = numOfSchedulingUnit;
      }
    } else if (this.stepForm.get('triggerFormGroup').get('trigger').value === 2) {
      scheduling.SchedulingType = SchedulingTypeEnum.Specified;
      scheduling.DelayInSeconds = 0;
      scheduling.DateTime = this.stepForm.get('triggerFormGroup').get('timeBasedWCCFormGroup').get('time').value;
      // need to sort out the loading in and editing...
    } else if (this.stepForm.get('triggerFormGroup').get('trigger').value === 18) {
      scheduling.SchedulingType = SchedulingTypeEnum.OneOffSend;
      scheduling.DelayInSeconds = 0;
      scheduling.DateTime = this.stepForm.get('triggerFormGroup').get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').value;
    } else {
      const schedulingType = this.stepForm.get('timingFormGroup').get('timingType').value;
      const numOfSchedulingUnit = this.stepForm.get('timingFormGroup').get('periodOfTimeFormGroup').get('numberOf').value;
      const schedulingUnit = this.stepForm.get('timingFormGroup').get('periodOfTimeFormGroup').get('minsHoursDaysWeeks').value;

      switch (schedulingType) {
        case 'immediately':
          scheduling.SchedulingType = SchedulingTypeEnum.Immediately;
          break;
        case 'periodOfTime':
          if (schedulingUnit === 1 || schedulingUnit === 2) {
            scheduling.SchedulingType = SchedulingTypeEnum.Delayed;
          } else {
            scheduling.SchedulingType = SchedulingTypeEnum.SpecifiedWithDelay;
            scheduling.DateTime = this.stepForm.get('timingFormGroup').get('periodOfTimeFormGroup').get('time').value;
          }
          scheduling.DelayInSeconds = this.calcSeconds(numOfSchedulingUnit, schedulingUnit, null);
          break;
        default:
          scheduling.SchedulingType = SchedulingTypeEnum.Invalid;
          break;
      }
    }

    return scheduling;
  }

  // This calcs the number of seconds from a unit of mins, hours, days or weeks. This is for a save of a Trigger.
  calcSeconds(numOf: number, schedulingUnit: number, beforeAfter: number) {
    var seconds = 0;
    const min = 60; // schedulingUnit = 1
    const hour = (min * 60); // schedulingUnit = 2
    const day = (hour * 24); // schedulingUnit = 3
    const week = (day * 7); // schedulingUnit = 4

    switch (schedulingUnit) {
      case 1:
        seconds = min;
        break;
      case 2:
        seconds = hour;
        break;
      case 3:
        seconds = day;
        break;
      case 4:
        seconds = week;
        break;
    }
    seconds = seconds * numOf;
    if (beforeAfter === 1) {
      seconds = -Math.abs(seconds);
    }
    return seconds;
  }

  // Given a number of seconds and a formGroup, we calc mins, hours, days or weeks from seconds,
  // this is when populating formGroups for an edit.
  calcFromSeconds(seconds: number, formGroup: AbstractControl) {

    if (formGroup === this.stepForm.controls.triggerFormGroup) {
      const beforeOrAfter = Math.sign(seconds);

      switch (beforeOrAfter) {
        case -1:
          this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('beforeAfter').setValue(1);
          seconds = (seconds * -1);
          break;
        case 0:
        case 1:
          this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('beforeAfter').setValue(2);
          break;
      }
    }

    var numOf: number;
    var unit: string;

    // Essentially here, we don't want 8 days saved, but a calculation to interpret that as '1.17 Weeks'.
    // If the value received from the calcWeeks() is not a whole number, we move onto the next one.

    if (seconds == 0) {
      numOf = this.calcDays(seconds);
      unit = 'days';
    } else if (this.calcWeeks(seconds) % 1 === 0) {
      numOf = this.calcWeeks(seconds);
      unit = 'weeks';
    } else if (this.calcDays(seconds) % 1 === 0) {
      numOf = this.calcDays(seconds);
      unit = 'days';
    } else if (this.calcHours(seconds) % 1 === 0) {
      numOf = this.calcHours(seconds);
      unit = 'hours';
    } else if (this.calcMinutes(seconds) % 1 === 0) {
      numOf = this.calcMinutes(seconds);
      unit = 'mins';
    }

    if (formGroup === this.stepForm.controls.triggerFormGroup) {

      this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('numberOf').setValue(numOf);

      switch (unit) {
        case 'weeks':
          this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('daysWeeksMonths').setValue(4);
          break;
        case 'days':
          this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('daysWeeksMonths').setValue(3);
          break;
      }
    } else {
      this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('numberOf').setValue(numOf);
      switch (unit) {
        case 'weeks':
          this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('minsHoursDaysWeeks').setValue(4);
          break;
        case 'days':
          this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('minsHoursDaysWeeks').setValue(3);
          break;
        case 'hours':
          this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('minsHoursDaysWeeks').setValue(2);
          break;
        case 'mins':
          this.stepForm.controls.timingFormGroup.get('periodOfTimeFormGroup').get('minsHoursDaysWeeks').setValue(1);
          break;
      }
    }
  }

  calcTimeFromDate(triggerDate: Date): string {

    return new DatePipe(this._dateCulture).transform(triggerDate, 'HH:mm');
  }

  calcFromMonths(numberOfMonths: number) {
    const beforeOrAfter = Math.sign(numberOfMonths);

    switch (beforeOrAfter) {
      case -1:
        this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('beforeAfter').setValue(1);
        numberOfMonths = (numberOfMonths * -1);
        break;
      case 1:
        this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('beforeAfter').setValue(2);
        break;
    }

    this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('numberOf').setValue(numberOfMonths);
    this.stepForm.controls.triggerFormGroup.get('timeBasedFormGroup').get('daysWeeksMonths').setValue(5);
  }

  calcWeeks(seconds: number): number {
    const weeks = seconds / 604800;
    return weeks;
  }

  calcDays(seconds: number): number {
    const days = seconds / 86400;
    return days;
  }

  calcHours(seconds: number): number {
    const hours = seconds / 3600;
    return hours;
  }

  calcMinutes(seconds: number): number {
    const mins = seconds / 60;
    return mins;
  }

  cancelClicked() {
    this.queryBuilderStorageService.ClearSQLFilterFromLocalStorage();

    this.location.back();
  }

  onListChanged(listId: number) {
    this.actionsTriggerComponent.populateTimeBasedDropdownWithContactCriteria(listId);
  }

  onMessageChanged() {
    this.stepForm.controls.triggerFormGroup.get('trigger').setValue(null);
    this.stepForm.controls.triggerFormGroup.get('messageLinkId').setValue(null);
    this.actionsTriggerComponent._messagesLinksDropdownPopulated = false;
    this.actionsTriggerComponent.clearLinks();
  }
}
