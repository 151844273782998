export const environment = {
  production: true,
  apiURL: "https://api.uat.voicebox.marketing.delivery/",
  formBuilderURL: 'https://formeditor.voicebox.uat2.marketing.delivery/formeditor.html',
  formPublishURL: 'https://forms.voicebox.uat2.marketing.delivery/?',
  emailBuilderURL: 'https://emaileditor.voicebox.uat2.marketing.delivery/editor.html',

  sessionIdlePeriod: 3600,
  sessionTimeoutPeriod: 30,
  maxClientBuckets: 2,
  vehicleValidationAPIUrl: 'https://vehiclevalidator.marketing.delivery/regnbr/json',
  vehicleValidationAPIKey: '',
};
