import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localStorage/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  marketingDeliveryDomains = ['marketing.delivery', 'marketingdelivery.com'];

  constructor(private localStorageService: LocalStorageService) { }

  validateMobilePhoneNumber(value: string)
  {
    if(!value) return '';
    let finalMobileValue = value.trim();
    
    // strip out non numeric numbers
    finalMobileValue = finalMobileValue.replace(/\D/g,'');

    // no leading zeros where 2 leading digits are not 44

    if(finalMobileValue.substring(0,3) === '000')
    {
      finalMobileValue = '44' + finalMobileValue.substring(3, finalMobileValue.length);
    }
    else if(finalMobileValue.substring(0,2) === '00')
    {
      finalMobileValue = '44' + finalMobileValue.substring(2, finalMobileValue.length);
    }
    else if(finalMobileValue.substring(0,1) === '0')
    {
      finalMobileValue = '44' + finalMobileValue.substring(1, finalMobileValue.length);
    }
    if(finalMobileValue.substring(0,2) !== '44')
    {
      finalMobileValue = '44' + finalMobileValue.substring(1, finalMobileValue.length);
    }

    // check length of cleansed mobile number and if it's less than 6, set the string to '' as the mobile number is invalid
    // if it's greater than 14, also set it to '' as it is invalid
    if(finalMobileValue.length < 5)
    {
      finalMobileValue = '';
    }
    else if(finalMobileValue.length > 14)
    {
      finalMobileValue = '';
    }

    return finalMobileValue;
  }

  isValidContactNumber(value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
  }

  //Customisable Export to CSV Function
  exportToCSV(filename: string, rows: object[]) {
   
    //Browser Navigator
	let nav: any;
	nav = window.navigator;
 
	if (!rows || !rows.length) return;
 
	const separator: string = ",";
	let keys: string[] = Object.keys(rows[0]);
	let columHeaders: string[] = keys;
	var exportedFilename = filename + '.csv';
  var universalBOM = "\uFEFF";
  
    //Generate CSV from Spec
	const csvContent =
		columHeaders.join(separator) +
		'\n' +
		rows.map(row => {
			return keys.map(k => {
				let cell = row[k] === null || row[k] === undefined ? '' : row[k].toString();       

				//Format Instance of Date
				cell = cell instanceof Date ?
					cell.toLocaleString() :
					cell.toString().replace(/"/g, '""');
	
				//remove non-ascii characters
				if (nav.msSaveBlob) cell = cell.replace(/[^\x00-\x7F]/g, "");
        
        //remove html breaks i.e. <br/>
        cell = cell.replace(/<br\/>/g, '');
 
				if (cell.search(/("|,|\n)/g) >= 0) cell = `"${cell}"`;
 
				if (this.isValidContactNumber(cell)) cell = `=\"${cell}\"`;
 
				return cell;
 
			}).join(separator);
			
		}).join('\n');    
 
    
		var blob = new Blob([csvContent], { type: 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvContent) });
		if (nav.msSaveBlob) nav.msSaveBlob(blob, exportedFilename)
		else {
			var link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				var url = URL.createObjectURL(blob);
				link.setAttribute("href", 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvContent));
				link.setAttribute("download", exportedFilename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
    
	}

  isLoggedInUserMDUser() {
    var domain = this.localStorageService.getEmail.split('@').pop();
    if (domain){
      return this.marketingDeliveryDomains.includes(domain);
    } else {
      return false;
    }
  }
}
